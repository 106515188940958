import { Dropdown, MenuProps } from 'antd'
import { ExportFileType } from 'shared'
import { DownloadOutlined } from '@ant-design/icons'
import { exportFileBtnItems } from './constants'
import { ExportFileBtnProps } from './types'
import { ExportFileBtnStyles } from './styles'

export const ExportFileBtn = (props: ExportFileBtnProps) => {
  const { onClick, loading, ...dropdownProps } = props

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    onClick(key as ExportFileType)
  }

  const menuProps = {
    items: exportFileBtnItems,
    onClick: handleMenuClick,
  }

  const handleButtonClick = () => onClick(ExportFileType.PDF)

  return (
    <ExportFileBtnStyles>
      <Dropdown.Button {...dropdownProps} menu={menuProps} onClick={handleButtonClick} loading={loading}>
        <DownloadOutlined />
        Export file
      </Dropdown.Button>
    </ExportFileBtnStyles>
  )
}
