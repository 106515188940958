import styled from 'styled-components'

export const DeletePayrollModalStyles = styled.div`
  .description {
    margin-bottom: 20px;
    line-height: 22px;
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
`
