// TODO: fix eslint error
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { ORG_CHART } from '../../../../utils/constants/api'
import { OrgUnitFromApi } from '../../../../shared/OrgChart/data/OrgUnitFromApi'
import DeleteOrgUnit from './Modal/DeleteOrgUnit'
import EditOrgUnit from './Modal/EditOrgUnit'
import CreateOrgUnit from './Modal/CreateOrgUnit'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { RolesEnum } from 'shared'
import { assertEmployee, useEmployee } from '../../../../contexts/EmployeeContext'

export const InnerTab = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)

  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([])
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const [editOrgUnitModal, setEditOrgUnitModal] = useState<boolean>(false)
  const [deleteOrgUnitModal, setDeleteOrgUnitModal] = useState<boolean>(false)
  const [orgUnitToChange, setOrgUnitToChange] = useState<OrgUnitFromApi | null>(null)
  const [orgUnitToDelete, deleteOrgUnit] = useState<OrgUnitFromApi | null>(null)
  const [createOrgUnitModal, setCreateOrgUnitModal] = useState<boolean>(false)

  function getOrgChart() {
    return fetch(`${ORG_CHART}?without-hierarchy=true`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((orgUnits: OrgUnitFromApi[]) => setOrgUnits(orgUnits))
  }

  useEffect(() => {
    getOrgChart()
  }, [])

  const toggleExpand = (id: string) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    })
  }

  function editOrgUnitModalVisibility(orgUnit: OrgUnitFromApi): void {
    setOrgUnitToChange(orgUnit)
    setEditOrgUnitModal(true)
  }

  function confirmDeleteOrgUnitModalVisibility(orgUnit: OrgUnitFromApi): void {
    deleteOrgUnit(orgUnit)
    setDeleteOrgUnitModal(true)
  }

  const isAdminRole = employee.roles.includes(RolesEnum.ROLE_ADMIN)

  const renderOrgUnit = (orgUnit: OrgUnitFromApi) => {
    const hasChildren = orgUnits.some((u) => u.parentId === orgUnit.id)
    return (
      <React.Fragment key={orgUnit.id}>
        <li
          onClick={() => toggleExpand(orgUnit.id)}
          onMouseEnter={() => {
            const elem = document.getElementById(orgUnit.id)
            if (elem) {
              elem.style.visibility = 'visible'
            }
          }}
          onMouseLeave={() => {
            const elem = document.getElementById(orgUnit.id)
            if (elem) {
              elem.style.visibility = 'hidden'
            }
          }}
        >
          <div className={`${!hasChildren ? 'without-child' : ''} orgUnit-block`}>
            {hasChildren && (
              <i className={`material-icons ${expanded[orgUnit.id] ? '' : 'material-icons-arrow-right'}`}>
                arrow_drop_down
              </i>
            )}
            <span className={`${hasChildren ? 'show-click' : ''}`}>{orgUnit.name}</span>
            {isAdminRole ? (
              <div id={orgUnit.id} className="btns-block" style={{ visibility: 'hidden' }}>
                <i
                  onClick={(event) => {
                    editOrgUnitModalVisibility(orgUnit)
                    event.stopPropagation()
                  }}
                  className="material-icons"
                >
                  edit
                </i>
                <i
                  onClick={(event) => {
                    confirmDeleteOrgUnitModalVisibility(orgUnit)
                    event.stopPropagation()
                  }}
                  className="material-icons"
                >
                  delete
                </i>
              </div>
            ) : null}
          </div>
        </li>
        {hasChildren && (
          <ul style={{ display: expanded[orgUnit.id] ? 'block' : 'none' }}>
            {orgUnits.map((u) => {
              if (u.parentId === orgUnit.id) {
                return renderOrgUnit(u)
              }
              return null
            })}
          </ul>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="vertical-tabs-content">
      {isAdminRole ? (
        <div className="add-button-block">
          <Button
            text="<i className='material-icons add-item'>add</i> Add org unit"
            size={Size.Medium}
            type={Type.Accent}
            onClick={() => setCreateOrgUnitModal(true)}
          />
        </div>
      ) : null}
      <ul>
        {orgUnits.map((orgUnit) => {
          if (!orgUnit.parentId) {
            return renderOrgUnit(orgUnit)
          }
          return null
        })}
      </ul>
      <CreateOrgUnit
        orgUnits={orgUnits}
        opened={createOrgUnitModal}
        onOk={() => {
          setCreateOrgUnitModal(false)
          getOrgChart()
        }}
        onClose={() => setCreateOrgUnitModal(false)}
      />
      {orgUnitToDelete && (
        <DeleteOrgUnit
          opened={deleteOrgUnitModal}
          onOk={() => {
            setDeleteOrgUnitModal(false)
            deleteOrgUnit(null)
            getOrgChart()
          }}
          onClose={() => {
            setDeleteOrgUnitModal(false)
            deleteOrgUnit(null)
          }}
          orgUnit={orgUnitToDelete}
        />
      )}
      {orgUnitToChange && (
        <EditOrgUnit
          orgUnits={orgUnits.filter((orgUnit) => orgUnit.id !== orgUnitToChange.id)}
          opened={editOrgUnitModal}
          onOk={() => {
            setEditOrgUnitModal(false)
            setOrgUnitToChange(null)
            getOrgChart()
          }}
          onClose={() => {
            setEditOrgUnitModal(false)
            setOrgUnitToChange(null)
          }}
          orgUnit={orgUnitToChange}
        />
      )}
    </div>
  )
}
