import { MilitaryRegistrationFormValues } from './types'

export const serializeMilitaryRegistration = (data: MilitaryRegistrationFormValues) => ({
  status: data.status ?? null,
  attachedTcrss: data.attachedTcrss ?? null,
  tcrssByRegistration: data.tcrssByRegistration?.trim() ?? null,
  basisDeferment: data.basisDeferment ?? null,
  dataClarified: data.dataClarified ?? null,
  documentLink: data.documentLink?.trim() ?? null,
})
