import { Form, DatePicker, DatePickerProps } from 'antd'
import { FieldValues, useController } from 'react-hook-form'
import { coreHRDateFormat, dateFormatDB } from 'utils/helpers/formatted-date'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { FormDatepickerProps } from './types'

export const FormDatepicker = <FormDataType extends FieldValues>(props: FormDatepickerProps<FormDataType>) => {
  const {
    name,
    label,
    tooltip,
    control,
    picker,
    format = coreHRDateFormat,
    placeholder,
    allowClear = false,
    onCustomChange,
    ...datePickerProps
  } = props

  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const fieldValue = (value && dayjs(value).isValid() ? dayjs(value) : null) as DatePickerProps['value']

  const handleChange = (date: Dayjs | null) => {
    const currentValue = date ? dayjs(date).format(dateFormatDB) : null
    onChange(currentValue)

    if (onCustomChange) {
      onCustomChange(currentValue)
    }
  }

  return (
    <>
      <Form.Item
        label={label}
        tooltip={tooltip}
        validateStatus={error && 'error'}
        help={error?.message}
        className={datePickerProps.className}
      >
        <DatePicker
          placeholder={placeholder}
          format={format}
          value={fieldValue}
          picker={picker}
          allowClear={allowClear}
          {...fieldProps}
          {...datePickerProps}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </>
  )
}
