// TODO: fix eslint errors
/* eslint-disable */
import { useState, useEffect } from 'react'
import CreateGender from './Modal/CreateGender'
import DeleteGender from './Modal/DeleteGender'
import EditGender from './Modal/EditGender'
import { GENDERS } from '../../../../../utils/constants/api'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { GenderFromApi } from '../../../../../shared/Employee/Gender/data/GenderFromApi'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'
import './Genders.scss'

export const Genders = () => {
  const [createGenderModal, setCreateGenderModal] = useState<boolean>(false)
  const [editGenderModal, setEditGenderModal] = useState<boolean>(false)
  const [deleteGenderModal, setDeleteGenderModal] = useState<boolean>(false)

  const [genders, setGenders] = useState<GenderFromApi[]>([])
  const [genderToChange, setGenderToChange] = useState<GenderFromApi | null>(null)
  const [genderToDelete, deleteGender] = useState<GenderFromApi | null>(null)

  const { employee } = useEmployee()
  const isAdminRole = employee?.roles.includes(RolesEnum.ROLE_ADMIN)

  useEffect(() => {
    getGenders()
  }, [])

  function getGenders() {
    fetch(GENDERS, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((genders: GenderFromApi[]) => setGenders(genders))
  }

  function editGenderModalVisibility(gender: GenderFromApi): void {
    setGenderToChange(gender)
    setEditGenderModal(true)
  }

  function confirmDeleteGenderModalVisibility(gender: GenderFromApi): void {
    deleteGender(gender)
    setDeleteGenderModal(true)
  }

  return (
    <div className="admin-panel-board">
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Gender Pronouns</h3>
          </div>
          {isAdminRole && (
            <div className="add-button-block">
              <Button
                text="<i className='material-icons add-item'>add</i> Add gender pronouns"
                size={Size.Medium}
                type={Type.Accent}
                onClick={() => setCreateGenderModal(true)}
              />
            </div>
          )}
        </div>
        <div className="sub-content-main">
          <table className="genders-table">
            <thead>
              <tr>
                <th>Gender Pronouns</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {genders.map((gender) => (
                <tr key={gender.id}>
                  <td title={gender.name}>{gender.name}</td>
                  <td>
                    {isAdminRole && (
                      <>
                        <i className="material-icons" onClick={() => editGenderModalVisibility(gender)}>
                          edit
                        </i>
                        <i className="material-icons" onClick={() => confirmDeleteGenderModalVisibility(gender)}>
                          delete
                        </i>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CreateGender
        opened={createGenderModal}
        onOk={() => {
          getGenders()
          setCreateGenderModal(false)
        }}
        onClose={() => setCreateGenderModal(false)}
      />
      {genderToDelete ? (
        <DeleteGender
          opened={deleteGenderModal}
          onOk={() => {
            getGenders()
            setDeleteGenderModal(false)
            deleteGender(null)
          }}
          onClose={() => {
            setDeleteGenderModal(false)
            deleteGender(null)
          }}
          gender={genderToDelete}
        />
      ) : null}
      {genderToChange ? (
        <EditGender
          opened={editGenderModal}
          onOk={() => {
            getGenders()
            setEditGenderModal(false)
            setGenderToChange(null)
          }}
          onClose={() => {
            setEditGenderModal(false)
            setGenderToChange(null)
          }}
          gender={genderToChange}
        />
      ) : null}
    </div>
  )
}
