import styled from 'styled-components'

export const FilterRangeCalendarStyles = styled.div`
  display: flex;
  gap: 4px;
  height: 32px;
  align-items: center;
  width: max-content;
  position: relative;

  .ant-picker {
    position: absolute;
    right: -102px;
  }

  .calendar-icon {
    font-size: 24px;
    color: rgba(68, 84, 111, 1);
  }

  .calendar {
    display: flex;
    cursor: pointer;
    gap: 16px;
    align-items: center;
  }

  .calendar-label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`
