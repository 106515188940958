import { MenuProps } from 'antd'
import { exportFileLabelMap, ExportFileType } from 'shared'

export const exportFileBtnItems: MenuProps['items'] = [
  {
    label: exportFileLabelMap[ExportFileType.PDF],
    key: ExportFileType.PDF,
  },
  {
    label: exportFileLabelMap[ExportFileType.CSV],
    key: ExportFileType.CSV,
  },
]
