export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RolesEnum {
  ROLE_UNAUTHORIZED = 'ROLE_UNAUTHORIZED',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
  ROLE_HR = 'ROLE_HR',
  ROLE_FINANCIAL_MANAGER = 'ROLE_FINANCIAL_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}
