import { SelectValue } from 'shared/Common/data/SelectValue'
import { MilitaryRegistrationFormValues } from './types'

export const defaultValues: MilitaryRegistrationFormValues = {
  status: null,
  attachedTcrss: null,
  tcrssByRegistration: null,
  basisDeferment: null,
  dataClarified: null,
  documentLink: null,
}

//TODO in future remove this. Data should be fetched from the server
export const registrationStatusOptions: SelectValue[] = [
  { value: 'Conscript', label: 'Conscript' },
  { value: 'Liable for military service', label: 'Liable for military service' },
  { value: 'Military personnel', label: 'Military personnel' },
  { value: 'Excluded from military registration', label: 'Excluded from military registration' },
  { value: 'Abroad', label: 'Abroad' },
  { value: 'Other nationality', label: 'Other nationality' },
  { value: 'Not provided', label: 'Not provided' },
]

export const radioGroupOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
