import styled from 'styled-components'

export const PayrollStyles = styled.div`
  width: 1144px;
  padding: 24px 0 0 24px;
  position: relative;

  .table-title {
    color: rgba(0, 0, 0, 0.88);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .ant-tabs > .ant-tabs-nav {
    display: none;
  }
`
