import { useMemo } from 'react'
import { Tooltip } from 'antd'
import { RolesEnum } from 'shared'
import { SelectionSelectFn } from 'antd/es/table/interface'
import { Table as CodeHRTable } from 'components'
import { TablePaginationData } from 'types'
import { PayrollSortBy } from 'services/api'
import { useEmployee } from 'contexts/EmployeeContext'
import { Payroll } from 'models'
import {
  defaultPayrollTableState,
  resetSelectedPayrolls,
  setPayrollTableState,
  setSelectedPayrolls,
  triggerNotification,
} from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getTableColumns } from './table-columns'
import { PayrollTableConfig, PayrollTableProps, TableRowSelection } from './types'
import { PayrollTableStyles } from './styles'

export const PayrollTable = (props: PayrollTableProps) => {
  const { isLoading, sourceData } = props

  const dispatch = useAppDispatch()

  const { employee } = useEmployee()

  const showRowSelection = employee?.roles.includes(RolesEnum.ROLE_FINANCIAL_MANAGER)

  const {
    tableState: { page, limit },
  } = useAppSelector((state) => state.payrollTable)
  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)
  const payrollType = useAppSelector((state) => state.payrollType)

  const tableColumns: PayrollTableConfig = useMemo(() => getTableColumns(), [])

  const selectedRowKeys = useMemo(() => {
    // If all payrolls are selected and table has active type,
    // return all payroll ids except the excluded ones
    if (selectedPayrollsData.selectedAll && payrollType === 'active') {
      return sourceData.items
        .filter((payroll) => !selectedPayrollsData.excludedIds.includes(payroll.id))
        .map((payroll) => payroll.id)
    }
    // If all payrolls are selected and table has closed type,
    // return all payroll ids except the excluded ones and only the last closed periods
    if (selectedPayrollsData.selectedAll && payrollType === 'closed') {
      return sourceData.items
        .filter((payroll) => !selectedPayrollsData.excludedIds.includes(payroll.id) && payroll.isLast)
        .map((payroll) => payroll.id)
    }

    return selectedPayrollsData.selectedPayrolls.map((payroll) => payroll.id)
  }, [selectedPayrollsData, sourceData.items, payrollType])

  // Count selected all payrolls
  const selectAllCount = useMemo(() => {
    if (payrollType === 'closed') {
      // For a closed payrolls table count only the last closed periods
      return sourceData.totalLast
    }
    return sourceData.total
  }, [sourceData, payrollType])

  const handleTableChange = (tableState: TablePaginationData<PayrollSortBy>) => {
    dispatch(setPayrollTableState(tableState))

    if (!selectedPayrollsData.selectedAll) {
      dispatch(resetSelectedPayrolls())
    }
  }

  // Select all payrolls in the table
  const handleSelectAll = () => {
    dispatch(
      setSelectedPayrolls({
        excludedIds: [],
        selectedPayrolls: [],
        selectedAll: true,
      }),
    )

    if (payrollType === 'closed') {
      dispatch(
        triggerNotification({
          type: 'info',
          message: 'Please note that only the most recent closed periods have been selected.',
        }),
      )
    }
  }

  // Clear all selected payrolls
  const handleClearAll = () => {
    dispatch(resetSelectedPayrolls())
  }

  // Select all payrolls on the current page
  const handleSelectCurrentPage = (currentRowKeys: React.Key[]) => {
    const selectedPayrollIds = sourceData.items.filter((payroll) => currentRowKeys.includes(payroll.id))

    dispatch(
      setSelectedPayrolls({
        selectedPayrolls: selectedPayrollIds,
        selectedAll: false,
        excludedIds: [],
      }),
    )
  }

  // Single row selection
  const handleSelectOn: SelectionSelectFn<Payroll> = (record, selected, selectedRows) => {
    if (selected && !selectedPayrollsData.selectedAll) {
      dispatch(
        setSelectedPayrolls({
          selectedPayrolls: selectedRows,
          selectedAll: false,
          excludedIds: [],
        }),
      )
    }

    if (!selected && selectedPayrollsData.selectedAll) {
      dispatch(
        setSelectedPayrolls({
          ...selectedPayrollsData,
          excludedIds: [...selectedPayrollsData.excludedIds, record.id],
        }),
      )
    }

    if (selected && selectedPayrollsData.selectedAll) {
      dispatch(
        setSelectedPayrolls({
          ...selectedPayrollsData,
          excludedIds: selectedPayrollsData.excludedIds.filter((id) => id !== record.id),
        }),
      )
    }

    if (!selectedPayrollsData.selectedAll) {
      dispatch(
        setSelectedPayrolls({
          selectedPayrolls: selectedRows,
          selectedAll: false,
          excludedIds: [],
        }),
      )
    }
  }

  const rowSelection: TableRowSelection<Payroll> = {
    selectedRowKeys,
    onSelect: handleSelectOn,
    selections: [
      {
        key: 'all',
        text: `Select all ${selectAllCount} payroll periods`,
        onSelect: handleSelectAll,
      },
      {
        key: 'current-page',
        text: 'Select current page',
        onSelect: handleSelectCurrentPage,
      },
      {
        key: 'clear',
        text: 'Clear all',
        onSelect: handleClearAll,
      },
    ],
    getCheckboxProps: (record: Payroll) => ({
      disabled: !record.isLast && payrollType === 'closed',
    }),
    renderCell: (_value: boolean, record: Payroll, _index: number, originNode: React.ReactNode) => (
      <Tooltip
        placement="bottomLeft"
        title={
          !record.isLast && payrollType === 'closed'
            ? 'You can’t delete this payroll period because it’s not the most recently closed period.'
            : null
        }
      >
        {originNode}
      </Tooltip>
    ),
  }

  return (
    <PayrollTableStyles>
      <CodeHRTable<Payroll, PayrollSortBy>
        loading={isLoading}
        columns={tableColumns}
        rowSelection={showRowSelection ? rowSelection : undefined}
        recordIdKey="id"
        defaultTableState={defaultPayrollTableState}
        dataSource={sourceData.items}
        onChange={handleTableChange}
        size="middle"
        rowClassName={(record) =>
          record.bonuses?.length > 1 || record.penalties?.length > 1 ? 'vertical-align-top' : ''
        }
        pagination={{
          pageSize: limit,
          total: sourceData.total,
          current: page,
        }}
      />
    </PayrollTableStyles>
  )
}
