import { useGetClosedPayrollDataQuery } from 'services/api'
import { useAppSelector } from 'hooks'
import { ClosedPayrollFilters, PayrollTable, DeletePayrollModal } from './components'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { NoData, NoResults } from 'components/UI/table'
import { useMemo } from 'react'
import { hasActiveFilter } from 'utils/helpers/has-active-filters'
import { defaultPayrollTableFilters } from 'store'

export const ClosedPayroll = () => {
  const { tableState, tableFilters } = useAppSelector((state) => state.payrollTable)
  const { isDeletePayrollModalOpen } = useAppSelector((state) => state.payrollActions)
  const payrollType = useAppSelector((state) => state.payrollType)

  const {
    data: closedPayrollDate = { items: [], total: 0 },
    isLoading,
    isFetching,
  } = useGetClosedPayrollDataQuery({ ...tableState, ...tableFilters.closed }, { skip: payrollType === 'active' })

  const hasActiveFilters = useMemo(() => {
    if (tableFilters.closed) {
      return hasActiveFilter(tableFilters.closed, defaultPayrollTableFilters.closed)
    }
    return false
  }, [tableFilters.closed])

  return (
    <>
      {isLoading && <SkeletonTable filtersCount={2} colCount={6} rowCount={10} />}
      <h3 className="table-title">Payroll reporting</h3>
      <ClosedPayrollFilters />

      {closedPayrollDate.total ? (
        <PayrollTable isLoading={isFetching} sourceData={closedPayrollDate} />
      ) : (
        <>{hasActiveFilters ? <NoResults /> : <NoData title="No data in closed payroll period" />}</>
      )}
      {isDeletePayrollModalOpen && <DeletePayrollModal />}
    </>
  )
}
