import { Form, Radio, Tooltip } from 'antd'
import { MilitaryRegistrationFormStyled } from './styles'
import { useMilitaryRegistrationForm } from './use-military-registration-form'
import { Controller } from 'react-hook-form'
import { BlockInfoItem, BlockInfoSkeletonItem } from 'components/Profile/BlockInfo'
import { radioGroupOptions, registrationStatusOptions } from './constants'
import { OfficialInformationSelect } from 'components/Profile/components/official-information-select/official-information-select'
import { InlineEditInput } from 'components/Profile/components/inline-edit-input'
import { CopyOutlined, ExportOutlined } from '@ant-design/icons'

export const MilitaryRegistrationForm = ({
  employeeId,
  hasEditAccess,
}: {
  employeeId: string
  hasEditAccess: boolean
}) => {
  const {
    isLoading,
    methods,
    editingFields,
    isValidLink,
    isLinkCopied,
    watchDocumentsLink,
    onSubmit,
    handleBlur,
    startEditing,
    stopEditing,
    handleCopyToClipboard,
  } = useMilitaryRegistrationForm(employeeId)

  return (
    <MilitaryRegistrationFormStyled>
      <div className="military-registration-title-block">
        <h4 className="military-registration-title">Military registration data</h4>
      </div>
      {isLoading ? (
        <>
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
        </>
      ) : (
        <Form onFinish={methods.handleSubmit(onSubmit)}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem title="Military registration status" className="military-registration-block-info">
                <div className="military-registration-form-field-wrapper">
                  <OfficialInformationSelect
                    {...field}
                    onSelect={handleBlur}
                    disabled={!hasEditAccess}
                    placeholder="–"
                    optionFilterProp="label"
                    options={registrationStatusOptions}
                    allowClear
                    onClear={handleBlur}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="attachedTcrss"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem title="Is attached to TCRSS?" className="military-registration-block-info">
                <div className="military-registration-form-field-wrapper">
                  <Radio.Group
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      handleBlur()
                    }}
                    options={radioGroupOptions}
                    disabled={!hasEditAccess}
                    className="military-registration-form-radio-group"
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="tcrssByRegistration"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem
                title={
                  <div>
                    TCRSS by military registration <br />
                    according to Oberig (Reserve+)
                  </div>
                }
                className="military-registration-block-info"
              >
                <div className="military-registration-form-field-wrapper">
                  <InlineEditInput
                    {...field}
                    hasEditAccess={hasEditAccess}
                    placeholder="e. g. TCRSS in Kyiv"
                    error={methods.formState.errors.tcrssByRegistration?.message}
                    onBlur={handleBlur}
                    editing={!!editingFields[field.name]}
                    onEditStart={() => startEditing(field.name)}
                    onEditCancel={(forceExit?: boolean) => {
                      stopEditing(field.name, forceExit)
                    }}
                    isSubmitting={methods.formState.isSubmitting}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="basisDeferment"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem
                title={
                  <div>
                    Is there a basis for deferment <br />
                    (not military reservation)?
                  </div>
                }
                className="military-registration-block-info"
              >
                <div className="military-registration-form-field-wrapper">
                  <Radio.Group
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      handleBlur()
                    }}
                    disabled={!hasEditAccess}
                    options={radioGroupOptions}
                    className="military-registration-form-radio-group"
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="dataClarified"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem
                title={
                  <div>
                    Is data clarified in Reserve+ or
                    <br />
                    Administrative Service Center?
                  </div>
                }
                className="military-registration-block-info"
              >
                <div className="military-registration-form-field-wrapper">
                  <Radio.Group
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      handleBlur()
                    }}
                    disabled={!hasEditAccess}
                    options={radioGroupOptions}
                    className="military-registration-form-radio-group"
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="documentLink"
            control={methods.control}
            render={({ field }) => (
              <div className="military-registration-link-wrapper">
                <BlockInfoItem
                  title="Link to military registration document"
                  className="military-registration-block-info"
                >
                  <div className="military-registration-form-field-wrapper">
                    <InlineEditInput
                      {...field}
                      hasEditAccess={hasEditAccess}
                      placeholder="https://"
                      error={methods.formState.errors.documentLink?.message}
                      onBlur={handleBlur}
                      editing={!!editingFields[field.name]}
                      onEditStart={() => startEditing(field.name)}
                      onEditCancel={(forceExit?: boolean) => {
                        stopEditing(field.name, forceExit)
                      }}
                      isSubmitting={methods.formState.isSubmitting}
                    />
                  </div>
                </BlockInfoItem>
                {isValidLink && (
                  <div className="military-registration-link-buttons">
                    <Tooltip title={isLinkCopied ? 'Copied' : 'Copy'} color="black">
                      <span onClick={handleCopyToClipboard} className="military-registration-link-button">
                        <CopyOutlined />
                      </span>
                    </Tooltip>
                    <Tooltip title="Open in new tab" color="black">
                      <a
                        href={watchDocumentsLink || '#official-information'}
                        target={watchDocumentsLink ? '_blank' : '_self'}
                        className="military-registration-link-button"
                        rel="noreferrer"
                      >
                        <ExportOutlined />
                      </a>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          />
        </Form>
      )}
    </MilitaryRegistrationFormStyled>
  )
}
