import { PayrollTableColumns } from './types'

export const tableColumnsMap: {
  [key in keyof PayrollTableColumns]: keyof PayrollTableColumns
} = {
  id: 'id',
  employeeFullName: 'employeeFullName',
  workingMinutes: 'workingMinutes',
  startDate: 'startDate',
  bonuses: 'bonuses',
  penalties: 'penalties',
  rate: 'rate',
}
