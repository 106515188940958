import styled from 'styled-components'

export const EmployeeTableItemStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-avatar-string {
    font-size: 14px;
  }

  .employee {
    display: flex;
    flex-direction: column;

    &-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 195px;
      overflow: hidden;
      line-height: 16px;

      a {
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-nickname {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 16px;
    }
  }
`
