import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { TooltipInfo } from './components'
import { EmployeeCompensationData } from 'services/api'
import { CompensationListConfig } from './types'

export const compensationListConfig = (data: EmployeeCompensationData): CompensationListConfig[] => [
  {
    label: 'Current rate',
    value: data.rate ? currencyFormatter(data.rate.value, data.rate.currency) : '-',
  },
  {
    label: 'Current MGP',
    value: data.mgp ? currencyFormatter(data.mgp.value, data.mgp.currency) : '-',
    action: <TooltipInfo title="Minimum guaranteed payment" />,
  },
]
