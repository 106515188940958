import { useEffect, useState } from 'react'
import { coreHRDateFormat, dateFormatDB } from 'utils/helpers/formatted-date'
import { DatePicker, Input } from 'antd'
import { CalendarOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import dayjs, { Dayjs } from 'dayjs'
import { FilterRangeDatesProps, RangeValuesState } from './types'
import { defaultRangeValueState } from './constants'
import { FilterRangeDatesStyles } from './styles'

export const FilterRangeDates = (props: FilterRangeDatesProps) => {
  const { onChange, value, placeholder } = props
  const [isShowRange, setIsShowRange] = useState(false)
  const [rangeData, setRangeDate] = useState<RangeValuesState>(defaultRangeValueState)

  const handleClick = () => {
    setIsShowRange(!isShowRange)
  }

  const handleClear = () => {
    setRangeDate(defaultRangeValueState)
    onChange([])
  }

  const handleChangeRange = (dates: NoUndefinedRangeValueType<Dayjs>, dateStrings: string[]) => {
    setRangeDate({
      label: dateStrings.join(' - '),
      value: dates,
    })
  }

  const handleOpenRange = (isOpen: boolean) => {
    setIsShowRange(isOpen)
  }

  useEffect(() => {
    if (!isShowRange && rangeData.label) {
      const formattedDate = rangeData.value.map((date) => dayjs(date).format(dateFormatDB))

      onChange(formattedDate)
    }
  }, [isShowRange])

  useEffect(() => {
    if (value && value.length) {
      const startDay = dayjs(value[0])
      const endDay = dayjs(value[1])

      setRangeDate({
        label: `${startDay.format(coreHRDateFormat)} - ${endDay.format(coreHRDateFormat)}`,
        value: [startDay, endDay],
      })
    }
  }, [value])

  return (
    <FilterRangeDatesStyles>
      <Input
        placeholder="default size"
        value={rangeData.label || placeholder}
        readOnly
        suffix={
          rangeData.label ? <CloseCircleOutlined onClick={handleClear} /> : <CalendarOutlined onClick={handleClick} />
        }
        onClick={handleClick}
        width="264px"
      />
      {isShowRange && (
        <DatePicker.RangePicker
          onOpenChange={handleOpenRange}
          onCalendarChange={handleChangeRange}
          format={coreHRDateFormat}
          value={rangeData.value}
          open={isShowRange}
          allowClear={false}
          placement="bottomRight"
        />
      )}
    </FilterRangeDatesStyles>
  )
}
