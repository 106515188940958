import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import { GetMilitaryReservationQuery, MilitaryReservation, UpdateMilitaryReservationPayload } from './types'

export const militaryReservationApi = createApi({
  reducerPath: 'militaryReservationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/employees`,
  }),
  tagTypes: ['militaryReservation'],
  endpoints: (build) => ({
    getMilitaryReservation: build.query<MilitaryReservation, GetMilitaryReservationQuery>({
      query: ({ employeeId }: GetMilitaryReservationQuery) => ({
        url: `${employeeId}/military-reservation-data`,
      }),

      providesTags: [{ type: 'militaryReservation' }],
    }),

    updateMilitaryReservation: build.mutation<MilitaryReservation, UpdateMilitaryReservationPayload>({
      query: ({ employeeId, employmentForm, reservation, dateEnd, status, criticality, notes }) => ({
        url: `${employeeId}/military-reservation-data`,
        method: 'PUT',
        body: {
          employmentForm,
          reservation,
          dateEnd,
          status,
          criticality,
          notes,
        },
      }),

      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'militaryReservation' }]
        }
        return []
      },
    }),
  }),
})

export const { useGetMilitaryReservationQuery, useUpdateMilitaryReservationMutation } = militaryReservationApi
