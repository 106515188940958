import type { TableProps } from 'antd'
import { SystemCurrency } from 'models'
import { TableAction } from './components/table-action'
import { tableColumnsMap } from './constants'

export const tableColumns: TableProps<SystemCurrency>['columns'] = [
  {
    title: 'Name',
    dataIndex: tableColumnsMap.name,
    sorter: true,
  },
  {
    title: 'ISO code',
    dataIndex: tableColumnsMap.code,
    sorter: true,
  },
  {
    title: 'Symbol',
    dataIndex: tableColumnsMap.symbol,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (_: unknown, record: SystemCurrency) => <TableAction record={record} />,
  },
]
