import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'
import { BASE_URL } from '../constants'
import { PayrollData, GetPayrollQuery, DeleteClosedPayrollsPayload, GetExportClosedPayrollFileQuery } from './types'

export const closedPayrollApi = createApi({
  reducerPath: 'closedPayrollApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
  tagTypes: ['closedPayroll'],
  endpoints: (build) => ({
    getExportClosedPayrollFile: build.query<string, GetExportClosedPayrollFileQuery>({
      query: (params: GetExportClosedPayrollFileQuery) => ({
        url: '/exports/payroll-reports-closed',
        params,
        method: 'GET',
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
    getClosedPayrollData: build.query<PayrollData, GetPayrollQuery>({
      query: (params: GetPayrollQuery) => ({
        url: `/payroll-reports?${queryString.stringify(params, { arrayFormat: 'comma' })}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'closedPayroll', id: 'LIST' }],
    }),
    deleteClosedPayroll: build.mutation<void, DeleteClosedPayrollsPayload>({
      query: (payload) => ({
        url: `/payroll-reports?${queryString.stringify(payload, { arrayFormat: 'comma' })}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'closedPayroll', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

// prettier-ignore
export const { 
  useGetClosedPayrollDataQuery, 
  useDeleteClosedPayrollMutation,
  useLazyGetExportClosedPayrollFileQuery
 } = closedPayrollApi
