import styled from 'styled-components'

export const StyleWrapper = styled.div`
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    min-height: 100px;
    background: #ffffff;
    border-radius: 4px;
    margin: 0;
    max-width: 740px;

    @include tablet() {
      max-width: 100%;
    }

    .required {
      color: #0052cc;
      margin-left: 3px;
    }
    .main-mail {
      .input-container {
        padding: 0;
      }
    }
    .info-header {
      border-bottom: 2px solid rgba(9, 30, 66, 0.06);
      width: 100%;
      padding: 0;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      .icon {
        background: transparent;
        border-radius: 4px;
        width: 28px;
        height: 28px;
        padding: 2px;
        margin-right: 12px;
        i {
          color: #ffffff;
        }
      }
      h3 {
        color: #172b4d;
        flex-shrink: 0;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        margin: 0;
      }
    }
    .info-body {
      width: 100%;
      padding: 22px 0 0;
      .profile-field {
        color: #38434c;
        font-size: 14px;
        font-family: inherit;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: auto;
        height: 32px;

        @include tablet() {
          flex-direction: column;
        }

        .phone-field {
          i {
            margin: 5px 0 0 4px;
          }

          p {
            margin-top: 6px;
          }
        }
        .email-field {
          i {
            margin: 9px 0 0 4px;
          }

          p {
            margin-top: 6px;
          }
        }
        .social-field {
          i {
            margin: 9px 0 0 4px;
          }
          p {
            margin-top: 6px;
          }
        }
        .date-of-birth {
          .react-datepicker {
            display: none !important;
          }
          .input {
            border-radius: 4px;
            height: 36px;
          }
          span {
            margin-left: -83px;
            margin-top: 6.5px;
            color: #757575 !important;
            font-weight: 500;
            font-size: 16px;
          }
        }
        input,
        select,
        textarea {
          cursor: pointer;
          box-shadow: none;
        }

        .css-13cymwt-control {
          box-sizing: border-box;
          outline: 0 !important;
          border: 1px solid #ffffff;
          border-radius: 4px;
          min-height: 36px !important;
          cursor: pointer;
          padding-right: 20px;
          &:hover {
            background: #f6f8fd;
            &::after {
              content: '';
              position: absolute;
              top: 100%;
              left: calc(100% - 15px);
              transform: translateX(-50%);
              margin-top: -20px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6a7885;
            }
          }
          &:focus {
            border: 1px solid #0052cc !important;
          }
        }
        .css-1nmdiq5-menu {
          z-index: 3;
          min-width: 100%;
          width: max-content;
          right: 0;
          div div {
            height: 36px;
          }
        }
        .css-1u9des2-indicatorSeparator {
          display: none;
        }
        .react-select__value-container {
          padding: 0;
        }
        .css-t3ipsp-control {
          border: 1px solid #0052cc !important;
          min-height: 36px;
          cursor: pointer;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(100% - 15px);
            transform: translateX(-50%);
            margin-top: -19px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6a7885;
          }
        }
        .css-t3ipsp-control:hover {
          border: 1px solid #0052cc !important;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(100% - 15px);
            transform: translateX(-50%);
            margin-top: -19px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6a7885;
          }
        }
        .profile-select {
          width: auto;
          white-space: nowrap;
          max-width: 500px;
          min-width: 228px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          font-family: $baseFont;
          line-height: 26px;
          border-radius: 4px;
          color: #38434c;
          border: none;
          margin-bottom: -5px;
          .css-9jq23d {
            padding: 0 0 0 6px !important;
            color: #38434c;
            cursor: pointer;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
          .css-1dyz3mf {
            display: flex;
          }
          ::placeholder {
            padding: 4px 12px;
            color: #afb7be;
          }
          ::-moz-placeholder {
            color: #afb7be;
            padding: 4px 12px;
          }
          :-ms-input-placeholder {
            color: #afb7be;
            padding: 4px 12px;
          }
          ::-ms-input-placeholder {
            color: #afb7be;
            padding: 4px 12px;
          }
        }
        .react-select__control:hover {
          background: #f6f8fd;
        }
        .react-select__control:focus {
          border: 1px solid #0052cc !important;
        }
        .react-select__indicators {
          display: none;
        }
        .css-1jqq78o-placeholder {
          cursor: pointer;
        }
        .react-select__control {
          outline: none;
          border: none;
          cursor: pointer;
          margin: -4px -12px;
          min-height: 36px !important;
          height: 36px;
          display: flex;
          align-items: flex-start;
        }
        .react-select__control--menu-is-open {
          border: 1px solid #0052cc !important;
        }
        .react-select__control--is-focused {
          outline: none;
        }
        .react-select__menu {
          z-index: 3;
          white-space: nowrap;
          width: auto;
        }
        .react-select__control:focus {
          border: none;
          outline: none;
        }
        .react-select__menu {
          .react-select__option {
            height: 36px;
          }
        }
        .react-select__value-container {
          margin-right: 0;
          padding: 4px 12px;
          display: flex;
          align-items: flex-start;
          &:focus {
            border: 1px solid #0052cc;
          }
        }
        .css-t3ipsp-control,
        .css-t3ipsp-control:hover {
          outline: none !important;
          box-shadow: none !important;
        }
        .profile-field-title {
          margin-right: 72px;
          min-width: 200px;
          display: flex;
        }
        .react-datepicker__input-container input {
          cursor: pointer;
          height: 25px !important;
          border: none;
          background: #ffffff;
        }
        .profile-field-value {
          display: flex;
          width: auto;
          position: relative;
          .css-1wy0on6 {
            display: none;
          }
          .css-1dimb5e-singleValue {
            color: #38434c;
          }
          #date-of-birth {
            border: none;
            box-sizing: border-box;
            outline: none;
            &:hover {
              background: #f6f8fd;
            }
            &:focus {
              border: 1px solid #0052cc;
            }
          }
          .horizontal-input {
            align-items: flex-start;
            display: flex;
            padding: 0;
          }
          .react-datepicker-wrapper {
            border: 0;
            display: inline-block;
            width: min-content;
            min-width: 135px;
            margin-top: 1px;
            .react-datepicker__input-container input {
              border-radius: 4px !important;
              color: #626f86 !important;
              font-family: inherit;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              margin-top: -5px;
            }
          }
          .calendar-today {
            pointer-events: none;
            position: absolute;
            margin-left: -206px;
            z-index: 1;
            cursor: pointer;
            top: 6px;
          }
          .react-datepicker__navigation--next,
          .react-datepicker__navigation--previous {
            top: 22px !important;
          }
          .social-elepsis {
            margin-top: 10px;
            margin-left: -5px;
          }
          .input .input-container {
            margin-top: 0;
            position: relative;
            width: auto !important;
            height: 36px;
            textarea {
              height: 36px;
              overflow: hidden;
              background: none;
              cursor: pointer;
              box-sizing: border-box;
              max-width: 500px;
              min-width: 228px;
              white-space: nowrap !important;
              padding: 4px 12px;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              font-family: $baseFont;
              line-height: 26px;
              border-radius: 4px;
              color: #38434c;
              border: 1px solid #ffffff;
              margin-top: 0;
              margin-right: 5px;
            }
            textarea:hover {
              background: #f6f8fd;
              max-height: 36px;
            }
            textarea:focus {
              border: 1px solid #0052cc;
              max-height: 36px;
            }
          }
        }
      }
    }
  }
`

export const StyledAiTabText = styled.span`
  color: #531dab;
`
