import styled from 'styled-components'

export const ClosedPayrollFiltersStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  .filters-body {
    display: flex;
    gap: 16px;
    align-items: center;
  }
`
