import { Checkbox, CheckboxOptionType } from 'antd'
import { useState, useMemo, ChangeEvent } from 'react'
import { FilterItemSearch, DropdownView, NoResult, Loader } from '../components'
import { FilterCheckboxGroupStyles } from './styles'
import { FilterCheckboxGroupProps } from './types'
import { getSelectedFormattedDate } from './helpers'

export const FilterCheckboxGroup = (props: FilterCheckboxGroupProps) => {
  const { options, label, search, onScroll, onSearch, onChange, value, loading = false } = props
  const [checkboxValues, setCheckboxValues] = useState<string[]>(value || [])
  const [searchValue, setSearchValue] = useState('')

  const showSearch = Boolean(search || onSearch)

  const setDisabledOptions = (option: CheckboxOptionType): boolean => {
    if (searchValue && !onSearch) {
      const currentSearchValue: string = searchValue.toLowerCase()
      const currentOptionValue: string = `${option.value}`.toLowerCase()
      const currentOptionTitle: string = `${option.title}`.toLowerCase()
      // disable all options except those that contain searchValue
      return !currentOptionValue.includes(currentSearchValue) && !currentOptionTitle.includes(currentSearchValue)
    }

    return false
  }

  const checkboxGroupOptions: CheckboxOptionType[] = useMemo(
    () =>
      options.map((option: CheckboxOptionType): CheckboxOptionType => {
        const optionIsDisabled = setDisabledOptions(option)

        return {
          ...option,
          disabled: optionIsDisabled,
        }
      }),
    [searchValue, options],
  )

  const isNoResults: boolean = useMemo(
    () => checkboxGroupOptions.every((option: CheckboxOptionType) => option.disabled),
    [checkboxGroupOptions],
  )

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)

    if (onSearch) {
      onSearch(event.target.value)
    }
  }

  const selectedData = useMemo(() => getSelectedFormattedDate(checkboxValues, options), [checkboxValues, options])

  const handleChange = (checkedValues: string[]) => {
    setCheckboxValues(checkedValues)
    onChange(checkedValues)
  }

  const handleClear = () => {
    setCheckboxValues([])
    onChange([])
  }

  return (
    <FilterCheckboxGroupStyles>
      <DropdownView
        label={label}
        onClear={handleClear}
        selectedCounter={selectedData.selectedCount}
        selectedLabel={selectedData.selectedLabel}
      >
        <>
          {showSearch && <FilterItemSearch placeholder="Search" value={searchValue} onChange={onChangeSearch} />}
          {isNoResults ? (
            <NoResult />
          ) : (
            <>
              {loading && <Loader />}
              <div onScroll={onScroll}>
                <Checkbox.Group<string> options={options} value={checkboxValues} onChange={handleChange} />
              </div>
            </>
          )}
        </>
      </DropdownView>
    </FilterCheckboxGroupStyles>
  )
}
