import { Button, Modal } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { DeleteModalStyles } from './styles'
import { useAppDispatch, useAppSelector } from 'hooks'
import { currenciesApi, useDeleteSystemCurrencyMutation } from 'services/api'
import { triggerNotification, closeDeleteSystemCurrencyModal } from 'store'

export const DeleteModal = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector((state) => state.deleteSystemCurrencyModal.payload)

  if (!data) return

  const [deleteSystemCurrency, { isLoading }] = useDeleteSystemCurrencyMutation()

  const handleDeleteSystemCurrency = async () => {
    try {
      await deleteSystemCurrency({ currencyId: data.id }).unwrap()
      dispatch(currenciesApi.util.invalidateTags(['currencies']))
      dispatch(
        triggerNotification({
          type: 'success',
          message: "You've deleted a currency.",
        }),
      )
      // TODO: Fix TS
    } catch (error: any) {
      if (error.status === 409) {
        dispatch(
          triggerNotification({
            type: 'error',
            message: 'You can’t delete this currency',
            description: 'This currency is already used for rates, bonuses, and penalties.',
          }),
        )
      }
      // Need for testing
      console.error({ error })
    } finally {
      dispatch(closeDeleteSystemCurrencyModal())
    }
  }

  const onCancel = () => dispatch(closeDeleteSystemCurrencyModal())

  return (
    <Modal open onCancel={onCancel} destroyOnClose width={400} footer={null}>
      <DeleteModalStyles>
        <p className="title">
          <CloseCircleFilled className="title__icon" /> Delete currency
        </p>
        <p className="currency">
          {data.code} - {data.name}
        </p>
        <div className="footer">
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="solid" loading={isLoading} onClick={handleDeleteSystemCurrency} color="danger">
            Delete
          </Button>
        </div>
      </DeleteModalStyles>
    </Modal>
  )
}
