import styled from 'styled-components'

export const ExportFileBtnStyles = styled.div`
  .ant-btn-compact-first-item {
    padding: 5px 16px 5px 5px;

    .anticon {
      font-size: 24px;

      svg {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    &:hover {
      .anticon {
        svg {
          color: #4096ff;
        }
      }
    }
  }
`
