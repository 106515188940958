import { PayrollsActionsState } from './types'

export const defaultPayrollActions: PayrollsActionsState = {
  isDeletePayrollModalOpen: false,
  isClosePayrollModalOpen: false,
  selectedPayrollsData: {
    selectedPayrolls: [],
    selectedAll: false,
    excludedIds: [],
  },
}
