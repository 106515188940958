import { NoResultsIcon } from 'components/icons'
import { EmptyTableTemplate } from '../empty-table-tamplate'
import { NoResultProps } from './types'

export const NoResults = ({ size = 'medium' }: NoResultProps) => (
  <EmptyTableTemplate size={size}>
    <>
      <NoResultsIcon />
      <h4 className="title">No results found</h4>
      <p className="description">
        Sorry, that filter combination has no results.
        <br />
        Please try different criteria.
      </p>
    </>
  </EmptyTableTemplate>
)
