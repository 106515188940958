// TODO: fix eslint errors
/* eslint-disable */
import { useEffect, useState } from 'react'
import { useProfile } from '../../../context'
import { CityOptionsProps, useCity } from '../../../../../API/profile/fields/generalInfo/city'
import { BlockInfoItem } from '../../../BlockInfo'
import cn from 'classnames'
import style from '../../../Profile.module.scss'
import InlineEdit from '@atlaskit/inline-edit'
import { Select } from '../../../../UI/Select'
import { InputText } from '../../../ViewingModes/InputText'
import { useSetField } from '../../../../../API/profile/setField/useSetField'

export const City = () => {
  const { isAccessEdit, profile, idOpenProfile, isCurrentUserProfile } = useProfile()

  const { fetchCity, isLoading } = useCity(profile.country!)

  const [city, setCity] = useState<Array<CityOptionsProps>>([])

  const [picked, setPicked] = useState<CityOptionsProps>()

  const { setField } = useSetField(idOpenProfile, true)

  useEffect(() => {
    profile.country &&
      fetchCity().then((response) => {
        setCity(response)
        if (profile.city) {
          const currentCity = response.find((city) => city.value === profile.city)

          setPicked(currentCity)
        }
      })

    if (!profile.country) {
      setCity([])
      setPicked(undefined)
    }
  }, [profile.country])

  return (
    <BlockInfoItem title="City">
      <div
        className={cn(style.inputWrapper, (isAccessEdit || isCurrentUserProfile) && style.profileInputTextIsEditing)}
      >
        <InlineEdit
          {...(!isAccessEdit && !isCurrentUserProfile && { isEditing: false })}
          hideActionButtons
          defaultValue={picked}
          editView={({ errorMessage, onChange, ...fieldProps }, ref) => (
            <Select
              {...fieldProps}
              onChange={(event: any) => {
                onChange(event)

                setField(['city', event?.value || 'null'].join('/'))
              }}
              appearance="subtle"
              spacing="compact"
              inputId="single-select-example-clearable1"
              isSearchable
              isClearable
              className={style.profileSelect}
              autoFocus
              openMenuOnFocus
              ref={ref}
              isLoading={isLoading}
              options={city}
              placeholder="Choose a Gender"
            />
          )}
          readView={() => <InputText value={picked?.label} />}
          onConfirm={(value) => setPicked(value)}
        />
      </div>
    </BlockInfoItem>
  )
}
