import { TabsProps } from 'antd'
import { PayrollTypeEnum } from 'shared'
import { ActivePayroll, ClosedPayroll } from './components'

export const payrollTabsConfig: TabsProps['items'] = [
  {
    key: PayrollTypeEnum.Active,
    label: null,
    children: <ActivePayroll />,
  },
  {
    key: PayrollTypeEnum.Closed,
    label: null,
    children: <ClosedPayroll />,
  },
]
