import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'
import {
  _ADMIN,
  AUTH,
  AUTH_SIGN_IN,
  CALENDAR,
  EMPLOYEE,
  EMPLOYEES,
  FORGOT_PASSWORD,
  GENDER_PRONOUNS,
  RANDOM_PHRASES,
  HOLIDAYS,
  JOB_TITLES,
  LEVELS,
  LINKS,
  LOCATIONS,
  MAIN,
  ORGCHART,
  RESET_PASSWORD,
  SET_PASSWORD,
  SIGN_IN,
  STATUSES,
  ADMIN_TIME_OFF_POLICIES,
  FORBIDDEN,
  REQUESTS,
  ATTENDANCE_LOG,
  SUBORDINATES_ANALYSIS,
  AI_ANALYSIS,
  SWAGGER,
  ANNOUNCEMENTS,
  BONUSES_PENALTIES,
  CURRENCIES,
  PAYROLL,
} from './constants'

import { RolesEnum } from 'shared'
import { ProvidersLoader } from '../ProvidersLoader'
import { AccessController } from '../HOC/AccessController'
import { Layout } from '../components/Layout'
import AuthPage from '../pages/auth/Auth'
import { SignInPage } from '../pages/auth/SignIn'
import SetPasswordPage from '../pages/auth/SetPassword'
import ForgotPasswordPage from '../pages/auth/ForgotPassword'
import { ResetPasswordPage } from '../pages/auth/ResetPassword'
import NewEmployeePage from '../pages/NewEmployee'
import AdminPage from '../pages/admin/Admin'
import RandomPhrasesPage from '../pages/admin/RandomPhrases'
import LinksPage from '../pages/admin/Links'
import { HolidaysPage } from '../pages/admin/Holidays'
import { OrgChartPage } from 'pages/admin/OrgChart'
import LocationsPage from 'pages/admin/Locations'
import GendersPage from '../pages/admin/Genders'
import LevelsPage from '../pages/admin/Levels'
import JobTitlesPage from '../pages/admin/JobTitles'
import TimeOffPoliciesPage from '../pages/admin/TimeOffPolicies'
import EmployeesPage from '../pages/admin/Employees'
import { SentryReporting } from 'components'
import {
  Announcements,
  NotFound,
  Forbidden,
  Calendar,
  Requests,
  AIAnalysis,
  Statuses,
  TeamAnalysis,
  AttendanceLog,
  Main,
  BonusesPenalties,
  Payroll,
} from 'pages'
import SwaggerComponent from '../pages/swagger/Swagger'
import { Currencies } from 'pages/currencies'

export const routerList: RouteObject[] = [
  {
    element: <ProvidersLoader />,
    errorElement: <SentryReporting />,
    children: [
      {
        path: MAIN,
        element: (
          <AccessController
            accessAllowedRoles={[RolesEnum.ROLE_EMPLOYEE, RolesEnum.ROLE_HR, RolesEnum.ROLE_ADMIN]}
            redirectToIfAccessDenied={AUTH_SIGN_IN}
          >
            <Layout />
          </AccessController>
        ),
        children: [
          { index: true, element: <Main /> },

          {
            path: EMPLOYEE,
            element: <NewEmployeePage />,
          },
          {
            path: CALENDAR,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_EMPLOYEE, RolesEnum.ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <Calendar />
              </AccessController>
            ),
          },
          {
            path: REQUESTS,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_EMPLOYEE, RolesEnum.ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <Requests />
              </AccessController>
            ),
          },
          {
            path: ADMIN_TIME_OFF_POLICIES,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <TimeOffPoliciesPage />
              </AccessController>
            ),
          },
          {
            path: STATUSES,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <Statuses />
              </AccessController>
            ),
          },
          {
            path: ANNOUNCEMENTS,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <Announcements />
              </AccessController>
            ),
          },
          {
            path: BONUSES_PENALTIES,
            element: (
              <AccessController accessAllowedRoles={[RolesEnum.ROLE_EMPLOYEE]} redirectToIfAccessDenied={FORBIDDEN}>
                <BonusesPenalties />
              </AccessController>
            ),
          },
          {
            path: ATTENDANCE_LOG,
            element: <AttendanceLog />,
          },
          {
            path: PAYROLL,
            element: <Payroll />,
          },
          {
            path: SUBORDINATES_ANALYSIS,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_HR, RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_EMPLOYEE]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <TeamAnalysis />
              </AccessController>
            ),
          },
          {
            path: AI_ANALYSIS,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_HR, RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_EMPLOYEE]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <AIAnalysis />
              </AccessController>
            ),
          },
          {
            path: CURRENCIES,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR, RolesEnum.ROLE_FINANCIAL_MANAGER]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <Currencies />
              </AccessController>
            ),
          },
          {
            path: _ADMIN,
            element: (
              <AccessController
                accessAllowedRoles={[RolesEnum.ROLE_HR, RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_FINANCIAL_MANAGER]}
                redirectToIfAccessDenied={FORBIDDEN}
              >
                <AdminPage />
              </AccessController>
            ),
            children: [
              {
                path: RANDOM_PHRASES,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <RandomPhrasesPage />
                  </AccessController>
                ),
              },
              {
                path: LINKS,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LinksPage />
                  </AccessController>
                ),
              },
              {
                path: HOLIDAYS,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <HolidaysPage />
                  </AccessController>
                ),
              },
              {
                path: ORGCHART,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR, RolesEnum.ROLE_FINANCIAL_MANAGER]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <OrgChartPage />
                  </AccessController>
                ),
              },
              {
                path: LOCATIONS,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR, RolesEnum.ROLE_FINANCIAL_MANAGER]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LocationsPage />
                  </AccessController>
                ),
              },
              {
                path: GENDER_PRONOUNS,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <GendersPage />
                  </AccessController>
                ),
              },
              {
                path: LEVELS,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <LevelsPage />
                  </AccessController>
                ),
              },
              {
                path: JOB_TITLES,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <JobTitlesPage />
                  </AccessController>
                ),
              },
              {
                path: EMPLOYEES,
                element: (
                  <AccessController
                    accessAllowedRoles={[RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR, RolesEnum.ROLE_FINANCIAL_MANAGER]}
                    redirectToIfAccessDenied={FORBIDDEN}
                  >
                    <EmployeesPage />
                  </AccessController>
                ),
              },
            ],
          },
          { path: FORBIDDEN, element: <Forbidden /> },
          { path: '*', element: <NotFound /> },
        ],
      },
      {
        path: AUTH,
        element: (
          <AccessController accessAllowedRoles={[RolesEnum.ROLE_UNAUTHORIZED]} redirectToIfAccessDenied={MAIN}>
            <AuthPage />
          </AccessController>
        ),
        children: [
          { index: true, element: <Navigate to={AUTH_SIGN_IN} replace /> },
          { path: SIGN_IN, element: <SignInPage /> },
          { path: SET_PASSWORD, element: <SetPasswordPage /> },
          { path: FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
          { path: RESET_PASSWORD, element: <ResetPasswordPage /> },
        ],
      },
      ...(process.env.REACT_APP_ENV === 'dev'
        ? [
            {
              path: SWAGGER,
              element: <SwaggerComponent />,
            },
          ]
        : []),
    ],
  },
]
