import styled from 'styled-components'
import { token } from '@atlaskit/tokens'

// prettier-ignore
export const StyledStatusesPage = styled.main`
  height: 100%;
  padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.300")};

  @media (min-width: 1920px) {
    padding: ${token("space.300")} ${token("space.800")} ${token("space.600")};
  }

  @media (max-width: 1280px) {
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")} ${token("space.200")};
  }

  @media (max-width: 1024px) {
    width: 100vw;
    padding: ${token("space.300")} ${token("space.400")} ${token("space.600")};
  }

  @media (max-width: 768px) {
    padding: ${token("space.300")} ${token("space.200")} ${token("space.600")};
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const StyledDynamicTable = styled.div`
  caption {
    font-family: inherit;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 0;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;

    th {
      height: 30px;
      vertical-align: middle;
      color: ${token('color.text.subtle')};

      &:first-of-type {
        padding-left: ${token('space.100')};
      }

      &:last-of-type {
        padding-right: ${token('space.100')};
      }
    }

    tr {
      min-height: 40px;
    }

    td {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;
      color: ${token('color.text.subtlest')};

      &:first-of-type {
        padding-left: ${token('space.100')};
      }

      &:last-of-type {
        padding-right: ${token('space.100')};
      }
    }
  }
`

export const StyledStatusRow = styled.div<{ $statusType: string }>`
  height: 100%;
  display: flex;
  color: ${(props) => {
    if (props.$statusType === 'empty_state') return token('color.text.disabled')
    return token('color.text.subtlest')
  }};
  align-items: center;
  justify-content: space-between;

  [role='presentation'] {
    width: 100%;
    margin: 0;
    cursor: pointer;

    & > div {
      margin-top: 0;
    }
  }
`

export const StyledEditableStatus = styled.span`
  display: inline-block;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: ${token('space.100')} 0;

  &:hover {
    background-color: ${token('color.background.neutral.subtle.hovered')};
    transition: background-color 0.2s;
  }
`

export const StyledSelectRow = styled.div`
  .work-status-select {
    &__control {
      &:hover {
        border-color: ${token('color.border')};
      }
    }

    &__value-container {
      padding: 0 2px;
    }

    &__multi-value {
      height: 30px;
      margin: 6px;
      align-items: center;
      padding-left: ${token('space.050')};

      &__label {
        color: ${token('color.text')};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        padding: 0;
      }

      &__remove {
        width: 16px;
        height: 100%;
        padding: 0;
      }
    }
  }
`

export const StyledAddStatusBtn = styled.span`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;

  & > div {
    width: 204px;
  }
`

export const StyledArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledToogleWrapper = styled.div`
  color: ${token('color.text.subtlest')};
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
`

//Popup styles
export const StyledPopupText = styled.div`
  font-family: inherit;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: ${token('space.200')};
  color: ${token('color.text')};
  width: 300px;
`

export const StyledBtnWrap = styled.div`
  & > button {
    color: ${token('color.icon.accent.gray')};

    &[aria-expanded='true'] {
      background: ${token('color.background.selected')};
      color: ${token('color.icon.selected')};
    }
  }
`
