import { Dropdown } from 'antd'
import { DropDownIcon } from 'components/icons'
import { generateProfileTabs } from './utils'
import { ProfileTabsProps } from './types'
import { StyledTabs, DropDownButton } from './styles'

export const ProfileTabs = ({ tabs, activeTab, onChange }: ProfileTabsProps) => {
  const handleMenuClick = ({ key }: { key: string }) => {
    onChange(key)
  }

  const handleTabChange = (key: string) => {
    if (key === 'dropdown') {
      return
    }
    onChange(key)
  }

  const { mainTabs, dropdownTabs } = generateProfileTabs(tabs, handleMenuClick)

  const items = [
    ...mainTabs,
    ...(!dropdownTabs.length
      ? []
      : [
          {
            key: 'dropdown',
            label: (
              <Dropdown menu={{ items: dropdownTabs }} trigger={['click']}>
                <DropDownButton>
                  More <DropDownIcon />
                </DropDownButton>
              </Dropdown>
            ),
          },
        ]),
  ]

  return <StyledTabs tabBarGutter={20} activeKey={activeTab} items={items} onChange={(key) => handleTabChange(key)} />
}
