import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import { PayrollData, GetPayrollQuery, CreateClosedPayrollsPayload, GetExportActivePayrollFileQuery } from './types'

export const activePayrollApi = createApi({
  reducerPath: 'activePayrollApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
  tagTypes: ['activePayrolls'],
  endpoints: (build) => ({
    getExportActivePayrollFile: build.query<string, GetExportActivePayrollFileQuery>({
      query: (params: GetExportActivePayrollFileQuery) => ({
        url: '/exports/payroll-reports-active',
        params,
        method: 'GET',
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
    getActivePayrollData: build.query<PayrollData, GetPayrollQuery>({
      query: (params: GetPayrollQuery) => ({
        url: '/payroll-reports-active',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'activePayrolls', id: 'LIST' }],
    }),
    createClosedPayroll: build.mutation<void, CreateClosedPayrollsPayload>({
      query: (params: CreateClosedPayrollsPayload) => ({
        url: '/payroll-reports',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'activePayrolls', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

// prettier-ignore
export const { 
  useGetActivePayrollDataQuery,
  useCreateClosedPayrollMutation, 
  useLazyGetExportActivePayrollFileQuery
} = activePayrollApi
