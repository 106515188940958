import { useGetActivePayrollDataQuery } from 'services/api'
import { useAppSelector } from 'hooks'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { NoData } from 'components/UI/table'
import { ActivePayrollFilters, PayrollTable, ClosePayrollModal } from './components'

export const ActivePayroll = () => {
  const { tableState, tableFilters } = useAppSelector((state) => state.payrollTable)
  const { isClosePayrollModalOpen } = useAppSelector((state) => state.payrollActions)
  const payrollType = useAppSelector((state) => state.payrollType)

  const {
    data: activePayrollDate = { items: [], total: 0 },
    isLoading,
    isFetching,
  } = useGetActivePayrollDataQuery({ ...tableState, ...tableFilters.active }, { skip: payrollType === 'closed' })

  return (
    <>
      {isLoading && <SkeletonTable filtersCount={2} colCount={6} rowCount={10} />}
      <h3 className="table-title">Payroll reporting</h3>
      <ActivePayrollFilters />
      {activePayrollDate.total ? (
        <PayrollTable isLoading={isFetching} sourceData={activePayrollDate} />
      ) : (
        <NoData title="No data in active payroll period" />
      )}
      {isClosePayrollModalOpen && <ClosePayrollModal />}
    </>
  )
}
