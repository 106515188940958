import { FC, type ReactNode } from 'react'
import { useEmployee } from '../../contexts/EmployeeContext'
import { Navigate } from 'react-router-dom'
import { RolesEnum } from 'shared'

interface AccessControllerProps {
  accessAllowedRoles: RolesEnum[]
  redirectToIfAccessDenied: string
  children: ReactNode
}

export const AccessController: FC<AccessControllerProps> = ({
  accessAllowedRoles,
  redirectToIfAccessDenied,
  children,
}) => {
  const { roles } = useEmployee()
  const isAccess = accessAllowedRoles.some((role) => roles.includes(role))

  if (isAccess) return <>{children}</>
  return <Navigate to={redirectToIfAccessDenied} replace />
}
