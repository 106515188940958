/* eslint-disable no-console */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import { GetOfficialInformationQuery, OfficialInformation, UpdateOfficialInformationPayload } from './types'

export const officialInformationApi = createApi({
  reducerPath: 'officialInformationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/employees`,
  }),
  tagTypes: ['officialInformation'],
  endpoints: (build) => ({
    getOfficialInformation: build.query<OfficialInformation, GetOfficialInformationQuery>({
      query: ({ employeeId }: GetOfficialInformationQuery) => ({
        url: `${employeeId}/official-info`,
      }),

      providesTags: [{ type: 'officialInformation' }],
    }),

    updateOfficialInformation: build.mutation<OfficialInformation, UpdateOfficialInformationPayload>({
      query: ({ employeeId, registeredAddress, personalIdNumber }) => ({
        url: `${employeeId}/official-info`,
        method: 'PUT',
        body: {
          registeredAddress,
          personalIdNumber,
        },
      }),

      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'officialInformation' }]
        }
        return []
      },
    }),
  }),
})

export const { useGetOfficialInformationQuery, useUpdateOfficialInformationMutation } = officialInformationApi
