import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setPayrollType } from '../payroll-type'
import { setPayrollFilters } from '../payroll-table'
import { defaultPayrollActions } from './constants'
import { PayrollsActionsState } from './types'

export const payrollActionsSlice = createSlice({
  name: 'payrollActions',
  initialState: defaultPayrollActions,
  reducers: {
    setDeletePayrollModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeletePayrollModalOpen = action.payload
    },
    setClosePayrollModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isClosePayrollModalOpen = action.payload
    },
    setSelectedPayrolls: (state, action: PayloadAction<PayrollsActionsState['selectedPayrollsData']>) => {
      state.selectedPayrollsData = action.payload
    },
    resetSelectedPayrolls: (state) => {
      state.selectedPayrollsData = defaultPayrollActions.selectedPayrollsData
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPayrollType, () => defaultPayrollActions)
    builder.addCase(setPayrollFilters, () => defaultPayrollActions)
  },
})

// prettier-ignore
export const { 
  setClosePayrollModalOpen, 
  setDeletePayrollModalOpen, 
  setSelectedPayrolls, 
  resetSelectedPayrolls } = payrollActionsSlice.actions
export const payrollsActionsReducer = payrollActionsSlice.reducer
