import { Form } from 'antd'
import { BlockInfoItem, BlockInfoSkeletonItem } from 'components/Profile/BlockInfo'
import { InlineEditInput } from 'components/Profile/components/inline-edit-input'
import { Controller } from 'react-hook-form'
import { OfficialInformationStyled } from './styles'
import type { OfficialInformationFormProps } from './types'
import { useOfficialInformationForm } from './use-official-information-form'

export const OfficialInformationForm = ({ employeeId, access }: OfficialInformationFormProps) => {
  const { methods, isLoading, editingFields, stopEditing, startEditing, handleBlur, onSubmit } =
    useOfficialInformationForm(employeeId)

  return (
    <OfficialInformationStyled>
      <div className="official-information-title-block">
        <h4 className="official-information-title">Official information</h4>
      </div>
      {isLoading ? (
        <>
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
        </>
      ) : null}
      <Form onFinish={methods.handleSubmit(onSubmit)}>
        <Controller
          name="personalIdNumber"
          control={methods.control}
          render={({ field }) => (
            <BlockInfoItem title="Personal Identification Number (PIN)">
              <div className="official-information-form-field-wrapper">
                <InlineEditInput
                  {...field}
                  hasEditAccess={access}
                  placeholder="e. g. 123456789012"
                  error={methods.formState.errors.personalIdNumber?.message}
                  onBlur={() => {
                    handleBlur()
                  }}
                  editing={!!editingFields[field.name]}
                  onEditStart={() => startEditing(field.name)}
                  onEditCancel={(forceExit?: boolean) => {
                    stopEditing(field.name, forceExit)
                  }}
                  isSubmitting={methods.formState.isSubmitting}
                />
              </div>
            </BlockInfoItem>
          )}
        />

        <Controller
          name="registeredAddress"
          control={methods.control}
          render={({ field }) => (
            <BlockInfoItem title="Registered Address">
              <div className="official-information-form-field-wrapper">
                <InlineEditInput
                  {...field}
                  hasEditAccess={access}
                  placeholder="e. g. 00000, Kyiv, 22 Khreshchatyk, apt. 5, Ukraine"
                  error={methods.formState.errors.registeredAddress?.message}
                  onBlur={() => {
                    handleBlur()
                  }}
                  editing={!!editingFields[field.name]}
                  onEditStart={() => startEditing(field.name)}
                  onEditCancel={(forceExit?: boolean) => {
                    stopEditing(field.name, forceExit)
                  }}
                />
              </div>
            </BlockInfoItem>
          )}
        />
      </Form>
    </OfficialInformationStyled>
  )
}
