import { TabContainer } from '../TabContainer'
import { OfficialInformationForm } from './components/official-information-form'
import { MilitaryInformationBlock } from './components/military-information-block/military-information-block'

export const OfficialInformation = ({ employeeId, access }: { employeeId: string; access: boolean }) => (
  <TabContainer>
    <OfficialInformationForm employeeId={employeeId} access={access} />
    <MilitaryInformationBlock employeeId={employeeId} access={access} />
  </TabContainer>
)
