// TODO: fix eslint errors
/* eslint-disable */
import { useState } from 'react'
import { useProfile } from '../../../context'
import { BlockInfoItem } from '../../../BlockInfo'
import InlineEdit from '@atlaskit/inline-edit'
import { Input } from '../../../../UI/Input'
import { InputText } from '../../../ViewingModes/InputText'
import { useSetField } from '../../../../../API/profile/setField/useSetField'
import cn from 'classnames'
import style from '../../../Profile.module.scss'

export const OtherNameEN = () => {
  const { isAccessEdit, profile, idOpenProfile, showErrorNotify } = useProfile()
  const [value, setValue] = useState<string>(profile.otherNameEn || '')

  const { setField } = useSetField(idOpenProfile, true)

  const [mainErrorStateServ, setMainErrorStateServ] = useState<{
    clearError?: () => void | undefined
    error?: string | undefined
    valueBeforeError: string
  }>({
    clearError: undefined,
    error: undefined,
    valueBeforeError: '',
  })

  return (
    <BlockInfoItem title="Other Name (EN)">
      <div className={cn(style.inputWrapper, isAccessEdit && style.profileInputTextIsEditing)}>
        <InlineEdit
          {...(!isAccessEdit && { isEditing: false })}
          {...(mainErrorStateServ.error && { isEditing: true })}
          defaultValue={mainErrorStateServ.valueBeforeError ? mainErrorStateServ.valueBeforeError : value}
          editView={({ errorMessage, onChange, ...fieldProps }) => (
            <Input
              {...fieldProps}
              value={value}
              onChange={(event) => {
                const isValid = /^([-'a-zA-Z\s]+)?$/.test(event.currentTarget.value)
                if (!isValid) {
                  return
                }
                onChange(event)

                const value = event.currentTarget.value.charAt(0).toUpperCase() + event.currentTarget.value.slice(1)
                setValue(value)
              }}
              autoFocus={!mainErrorStateServ.error}
              isInvalid={!!errorMessage || !!mainErrorStateServ.error}
              autoComplete="off"
              placeholder=""
              className={style.profileInput}
            />
          )}
          readView={() => <InputText value={value} />}
          onConfirm={(value) => {
            mainErrorStateServ.clearError && mainErrorStateServ.clearError()
            setMainErrorStateServ({
              error: undefined,
              valueBeforeError: '',
              clearError: undefined,
            })
            setField(['other-name-en', value || 'null'].join('/'))
              .then(() => {
                setValue(value)
              })
              .catch((error) => {
                setMainErrorStateServ({
                  clearError: showErrorNotify(String(error.message)),
                  error: String(error.message),
                  valueBeforeError: value,
                })
              })
          }}
        />
      </div>
    </BlockInfoItem>
  )
}
