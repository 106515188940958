import { Avatar, Collapse } from 'antd'
import { getAbbr } from 'utils/helpers/get-abbr'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { REQUESTS } from 'navigation'
import { Button, DropDownIcon, DropUpIcon, ShortcutIcon } from 'components'
import { ConflictsPayrollListProps, EmployeeCardType, LinksListProps } from './types'
import { ConflictsPayrollListStyles } from './styles'
import { CloseCircleFilled } from '@ant-design/icons'

export const ConflictsPayrollList = (props: ConflictsPayrollListProps) => {
  const { list, onClose } = props

  const employeeCard = ({ employeeAvatar, employeeFullName, employeeNickname }: EmployeeCardType) => (
    <div className="employee-container">
      <div className="employee-card">
        <Avatar
          size={32}
          alt="avatar"
          className="employee-avatar"
          src={employeeAvatar ? <LazyLoadImage src={employeeAvatar} effect="blur" width={32} height={32} /> : ''}
        >
          {getAbbr(`${employeeFullName}`)}
        </Avatar>
        <div className="employee">
          <span className="employee-name">{employeeFullName}</span>
          <span className="employee-nickname">{employeeNickname}</span>
        </div>
      </div>
      <div className="dropdown">Requests</div>
    </div>
  )

  const linksList = ({ startDate, employeeId, endDate, timeOff, bonuses }: LinksListProps) => (
    <div className="links-list">
      {timeOff && (
        <Link
          // Temp fix for the link
          // Need to make refactor and create normal query structure
          to={`${REQUESTS}?datefrom=${startDate}&dateto=${endDate}&employees=${employeeId}&statuses=New`}
          target="_blank"
        >
          Time off request <ShortcutIcon />
        </Link>
      )}
      {bonuses && (
        <Link to={`/employee/${employeeId}#compensation`} target="_blank">
          Bonus request <ShortcutIcon />
        </Link>
      )}
    </div>
  )

  return (
    <ConflictsPayrollListStyles>
      <div className="title">
        <h4>
          <CloseCircleFilled /> You cannot close payroll period
        </h4>
        <p>These employees have active requests</p>
      </div>
      <div className="payrolls-list">
        {list.map((payroll, index) => (
          <Collapse
            key={index}
            expandIcon={({ isActive }) => (isActive ? <DropUpIcon /> : <DropDownIcon />)}
            items={[
              {
                key: index.toString(),
                label: employeeCard({
                  employeeAvatar: payroll.employeeAvatar,
                  employeeFullName: payroll.employeeFullName,
                  employeeNickname: payroll.employeeNickname,
                }),
                children: linksList({
                  startDate: payroll.startDate,
                  employeeId: payroll.employeeId,
                  endDate: payroll.endDate,
                  timeOff: payroll.timeOff,
                  bonuses: payroll.bonuses,
                }),
              },
            ]}
            defaultActiveKey={['0']}
          />
        ))}
      </div>
      <div className="btn-group">
        <Button onClick={onClose}>Cancel</Button>
      </div>
    </ConflictsPayrollListStyles>
  )
}
