import { RefSelectProps, Select, SelectProps } from 'antd'
import { DropDownIcon } from 'components/icons'
import { forwardRef, RefObject, useState } from 'react'
import { OptionWrapperStyled, StyledSelectContainer } from './styles'

export const OfficialInformationSelect = forwardRef((props: SelectProps, ref) => {
  const [showSuffixIcon, setShowSuffixIcon] = useState(false)
  const { disabled } = props
  return (
    <StyledSelectContainer>
      <Select
        {...props}
        ref={ref as RefObject<RefSelectProps>}
        optionFilterProp="label"
        allowClear
        onMouseEnter={() => {
          setShowSuffixIcon(true)
        }}
        onMouseLeave={() => {
          setShowSuffixIcon(false)
        }}
        suffixIcon={showSuffixIcon && !disabled && <DropDownIcon />}
        className="official-information-select"
        dropdownRender={(menu) => <OptionWrapperStyled>{menu}</OptionWrapperStyled>}
      />
    </StyledSelectContainer>
  )
})
