import styled from 'styled-components'

export const MilitaryInformationBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .military-information-title {
    font-size: 20px;
    color: #172b4d;
    margin-bottom: 32px;
  }
`
