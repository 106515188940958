import styled from 'styled-components'

export const MilitaryRegistrationFormStyled = styled.div`
  .military-registration {
    &-title-block {
      display: flex;
      height: 28px;
      align-items: center;
      flex-shrink: 0;
      margin: 10px 0 16px 0;
      padding-bottom: 8px;
      border-bottom: 2px solid rgba(9, 30, 66, 0.06);
    }

    &-title {
      font-size: 14px;
      color: #172b4d;
      margin-bottom: 12px;
    }

    &-block-info {
      margin-bottom: 16px;
    }

    &-form-field-wrapper {
      display: flex;
      alignitems: center;
      gap: 8px;
    }

    &-form-radio-group {
      padding: 0 8px;

      & .ant-radio-wrapper {
        color: #626f86 !important;
      }
    }

    &-link-wrapper {
      display: flex;
      align-items: center;
    }

    &-link-buttons {
      display: flex;
      gap: 3px;
    }

    &-link-button {
      width: 32px;
      height: 32px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      margin-bottom: 16px;
      color: #000;

      &:hover {
        background: rgba(0, 0, 0, 0.06);
      }

      &:active {
        scale: 0.95;
      }
    }
  }
`
