import { useAppDispatch, useAppSelector } from 'hooks'
import { FilterCheckboxGroup, FilterRangeCalendar, FilterRangeDates } from 'components'
import { employeeRequestStatusSelectOptions } from 'shared/constants'
import { setBonusPenaltyFilters } from 'store'
import { BonusesPenaltiesFiltersStyles } from './styles'

export const BonusesPenaltiesFilters = () => {
  const dispatch = useAppDispatch()
  const { tableFilters } = useAppSelector((state) => state.bonusPenaltyTable)

  const handleChangeEffectiveDates = (dates: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        effectiveDate: dates,
      }),
    )
  }

  const handleChangeCreationDates = (dates: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        creationDate: dates,
      }),
    )
  }

  const handleChangeStatus = (statuses: string[]) => {
    dispatch(
      setBonusPenaltyFilters({
        statuses,
      }),
    )
  }

  return (
    <BonusesPenaltiesFiltersStyles>
      <FilterRangeCalendar onChange={handleChangeEffectiveDates} value={tableFilters.effectiveDate || 'thisMonth'} />
      <div className="filter-body">
        <FilterCheckboxGroup
          label="Statuses"
          options={employeeRequestStatusSelectOptions}
          onChange={handleChangeStatus}
          value={tableFilters.statuses}
        />
        <FilterRangeDates
          placeholder="Creation date"
          onChange={handleChangeCreationDates}
          value={tableFilters.creationDate}
        />
      </div>
    </BonusesPenaltiesFiltersStyles>
  )
}
