// TODO: fix eslint errors
/* eslint-disable */
import { useEffect, useRef, useState } from 'react'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { CreateEmployee } from './Modal/CreateEmployee'
import { EMPLOYEES } from '../../../../../../utils/constants/api'
import DeleteEmployee from './Modal/DeleteEmployee'
import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi'
import Select from 'components/old-ui/Input/SelectInput/SelectInput'
import { SelectValue } from '../../../../../../shared/Common/data/SelectValue'
import { EMPLOYEE_PROFILE } from '../../../../../../utils/constants/routes'
import { Link } from 'react-router-dom'
import { RolesEnum } from 'shared'
import Pagination from '../../../../../../utils/helpers/Pagination'
import { assertEmployee, useEmployee } from '../../../../../../contexts/EmployeeContext'
import './CurrentEmployees.scss'

export const CurrentEmployees = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)

  const perPageOptions: SelectValue[] = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
  ]
  const [perPage, setPerPage] = useState({ label: '50', value: '50' })
  const [page, setPage] = useState<string>('1')
  const [createEmployeeModal, setCreateEmployeeModal] = useState<boolean>(false)
  const [employees, setEmployees] = useState<EmployeeFromApi[]>([])
  const [employeeToDelete, deleteEmployee] = useState<EmployeeFromApi | null>(null)
  const [deleteEmployeeModal, setDeleteEmployeeModal] = useState<boolean>(false)
  const [employed, setEmployed] = useState<string>('0')
  const fetchIdRef = useRef(0)
  const roles = [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_HR]
  const isEditRole = roles.some((role) => employee?.roles.includes(role))

  useEffect(() => {
    getEmployees()
  }, [page, perPage])

  useEffect(() => {
    getEmployed()
  }, [])

  useEffect(() => {
    setPage('1')
  }, [perPage])

  function getEmployees() {
    const currentFetchId = ++fetchIdRef.current
    const pageParam = page || '1'
    const perPageParam = perPage ? perPage.value : '50'
    return fetch(`${EMPLOYEES}/type/active?page=${pageParam}&per-page=${perPageParam}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((employees: EmployeeFromApi[]) => {
        if (currentFetchId === fetchIdRef.current) {
          setEmployees(employees)
        }
      })
  }

  function getEmployed() {
    return fetch(`${EMPLOYEES}/total?status=active`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((employed: string) => setEmployed(employed))
  }

  function confirmDeleteEmployeeModalVisibility(employee: EmployeeFromApi): void {
    deleteEmployee(employee)
    setDeleteEmployeeModal(true)
  }

  return (
    <div className="former-employees">
      {isEditRole && (
        <div className="add-button-block">
          <Button
            text="<i className='material-icons add-item'>add</i> Add Employee"
            size={Size.Medium}
            type={Type.Accent}
            onClick={() => setCreateEmployeeModal(true)}
          />
        </div>
      )}
      <table className="current-employees-table">
        <thead>
          <tr>
            <th>Employee</th>
            <th>First Name(EN)</th>
            <th>Last Name(EN)</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => (
            <tr key={employee.id}>
              <td>
                <Link to={`${EMPLOYEE_PROFILE}/${employee.id}`} target="_blank">
                  {employee.avatar ? (
                    <div className="with-avatar">
                      <img src={employee.avatar} alt="cropped" />
                    </div>
                  ) : (
                    <div className="without-avatar">
                      <p>{employee.firstNameEn.charAt(0).toUpperCase()}</p>
                    </div>
                  )}
                </Link>
              </td>
              <td>{employee.firstNameEn}</td>
              <td>{employee.lastNameEn}</td>
              <td>{employee.email}</td>
              <td>
                {isEditRole && (
                  <>
                    <Link to={`${EMPLOYEE_PROFILE}/${employee.id}`}>
                      <i title="edit profile" className="material-icons">
                        edit
                      </i>
                    </Link>
                    <i
                      className="material-icons"
                      onClick={() => confirmDeleteEmployeeModalVisibility(employee)}
                      title="delete employee"
                    >
                      delete
                    </i>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="pagination-info">
          <span>
            <i
              onClick={() => parseInt(page) > 1 && setPage((parseInt(page) - 1).toString())}
              className={`material-icons left ${page === '1' ? 'disabled-icon' : ''}`}
            >
              keyboard_arrow_left
            </i>
          </span>
          {page} / {Math.ceil(parseInt(employed) / parseInt(perPage.value))}
          <span>
            <i
              onClick={() =>
                parseInt(page) < Math.ceil(parseInt(employed) / parseInt(perPage.value)) &&
                setPage((parseInt(page) + 1).toString())
              }
              className={`material-icons right ${page === Math.ceil(parseInt(employed) / parseInt(perPage.value)).toString() ? 'disabled-icon' : ''}`}
            >
              keyboard_arrow_right
            </i>
          </span>
          <span className="pagination-total">
            {employed} <span>results</span>
          </span>
        </div>
        <Pagination
          currentPage={parseInt(page)}
          totalPages={Math.ceil(parseInt(employed) / parseInt(perPage.value))}
          setPage={(page) => setPage(page.toString())}
        />
        <div className="rows-per-page">
          <span>Rows per page:</span>
          <div className="rows-per-page-filter">
            <Select
              label=""
              required={false}
              error=""
              isMulti={false}
              value={perPage}
              options={perPageOptions}
              onChange={(e: any) => {
                setPerPage(e)
              }}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      <CreateEmployee
        opened={createEmployeeModal}
        onOk={() => {
          getEmployees()
          getEmployed()
          setCreateEmployeeModal(false)
        }}
        onClose={() => setCreateEmployeeModal(false)}
      />
      {employeeToDelete ? (
        <DeleteEmployee
          opened={deleteEmployeeModal}
          onOk={() => {
            getEmployees()
            getEmployed()
            setDeleteEmployeeModal(false)
            deleteEmployee(null)
          }}
          onClose={() => {
            setDeleteEmployeeModal(false)
            deleteEmployee(null)
          }}
          employee={employeeToDelete}
        />
      ) : null}
    </div>
  )
}
