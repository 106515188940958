import { GetCurrenciesPayload } from 'services/api/currencies/types'
import { SystemCurrenciesTableColumns } from './types'
import { OrderEnum } from 'shared'

export const defaultCurrenciesTableState: GetCurrenciesPayload = {
  currencyId: '',
  sort: 'name',
  order: OrderEnum.ASC,
  search: '',
}

export const tableColumnsMap: {
  [key in keyof SystemCurrenciesTableColumns]: keyof SystemCurrenciesTableColumns
} = {
  id: 'id',
  currencyId: 'currencyId',
  code: 'code',
  name: 'name',
  symbol: 'symbol',
}
