import { Modal } from 'antd'
import { Button } from 'components'
import { DeleteClosedPayrollsPayload, useDeleteClosedPayrollMutation } from 'services/api'
import { useAppDispatch, useAppSelector } from 'hooks'
import { resetPayrollTableState, resetSelectedPayrolls, setDeletePayrollModalOpen, triggerNotification } from 'store'
import { DeletePayrollModalStyles } from './styles'

export const DeletePayrollModal = () => {
  const dispatch = useAppDispatch()

  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)

  const [deleteClosedPayrolls, { isLoading: isDeletingClosedPayrolls }] = useDeleteClosedPayrollMutation()

  const handleCloseModal = () => {
    dispatch(setDeletePayrollModalOpen(false))
  }

  const handleDeletePayroll = async () => {
    const ids = selectedPayrollsData.selectedPayrolls.map((payroll) => payroll.id)

    const deletedCount = ids.length || 'all'

    const payload: DeleteClosedPayrollsPayload = {
      ids,
      all: selectedPayrollsData.selectedAll,
      excludedIds: selectedPayrollsData.excludedIds,
    }

    try {
      await deleteClosedPayrolls(payload).unwrap()

      dispatch(
        triggerNotification({
          type: 'success',
          message: 'Successfully deleted payroll period',
          description: `You’ve deleted ${deletedCount} payroll periods`,
        }),
      )

      if (selectedPayrollsData.selectedAll) {
        dispatch(resetPayrollTableState())
      }

      dispatch(resetSelectedPayrolls())
      handleCloseModal()
    } catch (error) {
      console.error('Error creating incentive', error)
    }
  }

  return (
    <Modal
      title="Delete payroll period"
      open
      footer={null}
      onCancel={handleCloseModal}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <DeletePayrollModalStyles>
        <p className="description">
          Deleting the payroll period implies opening and transitioning this period to active status. Only the most
          recently closed period can be deleted.
        </p>
        <div className="btn-group">
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" danger onClick={handleDeletePayroll} loading={isDeletingClosedPayrolls}>
            Delete payroll period
          </Button>
        </div>
      </DeletePayrollModalStyles>
    </Modal>
  )
}
