import { notification } from 'antd'

interface DownloadFileProps {
  url: string
  fileName: string
}

export const downloadFile = ({ url, fileName }: DownloadFileProps) => {
  if (!url) {
    return notification.error({
      message: 'Download failed',
      description: 'File not found',
      duration: 10,
      placement: 'bottomLeft',
    })
  }
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}
