import styled from 'styled-components'

export const PayrollTableStyles = styled.div`
  .ant-table-thead {
    th {
      .ant-checkbox-wrapper {
        display: none;
      }

      .ant-table-selection-extra {
        top: -14px;
        left: -8px;

        .anticon {
          font-size: 14px;
        }
      }
    }
  }

  .ant-table-tbody > tr.vertical-align-top {
    vertical-align: top;

    td label {
      margin-top: 4px;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
`
