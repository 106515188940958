import { getFormattedDate } from 'utils/helpers/formatted-date'
import { DeleteInfoStyled } from './styles'
import { DeleteInfoProps } from './types'
import { currencyFormatter } from 'utils/helpers/currency-formatter'

export const DeleteInfo = (props: DeleteInfoProps) => {
  const { employeeFullName, amount, date, currency } = props

  return (
    <DeleteInfoStyled>
      <ul className="deleted-info-list">
        <li className="list-item">
          <span className="label">Delete MGP for</span>
          <span className="value">{employeeFullName}</span>
        </li>
        <li className="list-item">
          <span className="label">Start date </span>
          <span className="value">{getFormattedDate(date, 'coreHRDateFormat')}</span>
        </li>
        <li className="list-item">
          <span className="label">Amount</span>
          <span className="value">{currencyFormatter(amount, currency.symbol)}</span>
        </li>
      </ul>
    </DeleteInfoStyled>
  )
}
