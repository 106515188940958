// TODO: fix eslint error
/* eslint-disable */
import { forwardRef, type HTMLProps } from 'react'
import cn from 'classnames'
import commonStyle from '../ViewingModes.module.scss'
import { TagGroup, Tag } from '../../../UI/Tag'
import { useProfile } from '../../context'

interface MultiSelectProps extends HTMLProps<HTMLDivElement> {
  value?: Array<any>
}

export const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(({ value, className }) => {
  const { isAccessEdit } = useProfile()

  const classNames = cn(
    commonStyle.profileInputText,
    value && value.length > 0 && commonStyle.profileInputMultiSelect,
    className,
  )

  return (
    <div className={classNames}>
      {!value || value.length === 0 ? (
        '–'
      ) : (
        <TagGroup>
          {value.map((option: any) => (
            <Tag isRemovable={isAccessEdit} text={option.label} key={option.label} />
          ))}
        </TagGroup>
      )}
    </div>
  )
})
