import { useState } from 'react'

export const useCopyToClipboard = () => {
  const [isCopied, setCopied] = useState(false)
  function copyToClipboard(text: string) {
    try {
      if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
        navigator.clipboard.writeText(text)
        setCopied(true)
        return true
      }

      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.style.position = 'absolute'
      textarea.style.left = '-9999px'
      document.body.appendChild(textarea)

      textarea.select()
      document.execCommand('copy')

      document.body.removeChild(textarea)
      setCopied(true)
      return true
    } catch (error) {
      console.error('copyToClipboard error:', error)
    } finally {
      setTimeout(() => setCopied(false), 1500)
    }
  }

  return { copyToClipboard, isCopied }
}
