import { MilitaryInformationBlockStyled } from './styles'
import { MilitaryRegistrationForm } from './components/military-registration-form/military-registration-form'
import { MilitaryReservationForm } from './components/military-reservation-form/military-reservation-form'

export const MilitaryInformationBlock = ({ employeeId, access }: { employeeId: string; access: boolean }) => (
  <MilitaryInformationBlockStyled>
    <h4 className="military-information-title">Military registration information</h4>
    <MilitaryRegistrationForm employeeId={employeeId} hasEditAccess={access} />
    <MilitaryReservationForm employeeId={employeeId} hasEditAccess={access} />
  </MilitaryInformationBlockStyled>
)
