import { DeleteFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useLazyGetExportClosedPayrollFileQuery } from 'services/api'
import { ExportFileType } from 'shared'
import { downloadFile } from 'utils/helpers/download-file'
import { Button, FilterRangeCalendar, ExportFileBtn } from 'components'
import { defaultPayrollTableFilters, setDeletePayrollModalOpen, setPayrollFilters } from 'store'
import { useAppSelector } from 'hooks'
import { TabButtons } from '../tab-buttons'
import { ClosedPayrollFiltersStyles } from './styles'

export const ClosedPayrollFilters = () => {
  const dispatch = useDispatch()

  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)
  const {
    tableFilters,
    tableState: { sort, order },
  } = useAppSelector((state) => state.payrollTable)

  const [trigger, { isFetching }] = useLazyGetExportClosedPayrollFileQuery()

  const showDeletePayrollButton = Boolean(
    selectedPayrollsData.selectedPayrolls.length || selectedPayrollsData.selectedAll,
  )

  const defaultCreationDateValue = defaultPayrollTableFilters.closed?.periodDates || 'thisMonth'

  const handleChangeCreationDate = (dates: string[]) => {
    dispatch(setPayrollFilters({ closed: { periodDates: dates } }))
  }

  const handleOpenDeletePayrollModal = () => {
    dispatch(setDeletePayrollModalOpen(true))
  }

  const handleButtonClick = async (fileType: ExportFileType) => {
    try {
      const url = await trigger({
        format: fileType,
        order,
        sort,
        periodDates: tableFilters.closed?.periodDates,
      }).unwrap()
      downloadFile({ url, fileName: `closed_payrolls.${fileType}` })
    } catch (err) {
      console.error('Error downloading file:', err)
    }
  }

  return (
    <ClosedPayrollFiltersStyles>
      <FilterRangeCalendar
        onChange={handleChangeCreationDate}
        value={tableFilters.closed?.periodDates || defaultCreationDateValue}
      />
      <div className="filters-body">
        <TabButtons />
        <ExportFileBtn loading={isFetching} onClick={handleButtonClick} />
        {showDeletePayrollButton && (
          <Button type="primary" danger icon={<DeleteFilled />} onClick={handleOpenDeletePayrollModal}>
            Delete payroll period
          </Button>
        )}
      </div>
    </ClosedPayrollFiltersStyles>
  )
}
