import * as yup from 'yup'
import { OfficialInformationFormValues } from './types'

export const validationSchema: yup.ObjectSchema<OfficialInformationFormValues> = yup.object({
  personalIdNumber: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .min(9, 'PIN must be at least 9 characters')
    .max(16, 'PIN must be at most 16 characters')
    .test('is-valid-pin', 'PIN format is not valid', (value) => {
      if (!value) return true
      return /^[A-Z0-9]+(-[A-Z0-9]+)*$/.test(value)
    })
    .notRequired(),

  registeredAddress: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .max(255, 'Registeed address must be at most 255 characters')
    .notRequired(),
})
