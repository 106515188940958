import styled from 'styled-components'

export const ActivePayrollFiltersStyles = styled.div`
  margin-bottom: 24px;

  .filters-header {
    display: inline-flex;
    height: 32px;
    gap: 4px;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .filters-body {
    display: flex;
    gap: 16px;
    align-items: center;
  }
`
