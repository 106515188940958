import styled from 'styled-components'

export const OfficialInformationStyled = styled.div`
  .official-information {
    &-title-block {
      display: flex;
      height: 28px;
      align-items: center;
      flex-shrink: 0;
      margin: 10px 0 16px 0;
      padding-bottom: 8px;
      border-bottom: 2px solid rgba(9, 30, 66, 0.06);
    }

    &-title {
      font-size: 14px;
      color: #172b4d;
      margin-bottom: 12px;
    }

    &-form-field-wrapper {
      display: flex;
      alignitems: center;
      gap: 8px;
    }
  }
`
