import { type FC, type ReactNode } from 'react'
import style from './BlockInfo.module.scss'

interface BlockInfoItemProps {
  title: ReactNode
  required?: boolean
  children: ReactNode
  className?: string
}

export const BlockInfoItem: FC<BlockInfoItemProps> = ({ title, required = false, children, className }) => (
  <tr className={`${style.rowContent} ${className}`}>
    <td className={style.titleItem}>
      {title}
      {required ? <span className={style.required}>*</span> : ''}
    </td>
    <td className={style.contentItem}>{children}</td>
  </tr>
)
