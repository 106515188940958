import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultDeleteSystemCurrencyModalState } from './constants'
import { DeleteSystemCurrencyModalState } from './types'

export const deleteSystemCurrencyModalSlice = createSlice({
  name: 'deleteSystemCurrencyModal',
  initialState: defaultDeleteSystemCurrencyModalState,
  reducers: {
    openDeleteSystemCurrencyModal: (_state, action: PayloadAction<DeleteSystemCurrencyModalState>) => ({
      isOpen: true,
      ...action.payload,
    }),
    closeDeleteSystemCurrencyModal: () => defaultDeleteSystemCurrencyModalState,
  },
})

export const { openDeleteSystemCurrencyModal, closeDeleteSystemCurrencyModal } = deleteSystemCurrencyModalSlice.actions
export const deleteSystemCurrencyModalReducer = deleteSystemCurrencyModalSlice.reducer
