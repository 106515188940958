// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, useEffect, useState, useCallback } from 'react'
import './JobInfo.scss'
import { EmployeeFromApi } from '../../../../../../shared/Employee/data/EmployeeFromApi'
import { EmployeeStatus } from './EmployeeStatus'
import { CurrentJobInfoFromApi } from '../../../../../../shared/Employee/Profile/JobInfo/data/CurrentJobInfoFromApi'
import { assertEmployee, useEmployee } from '../../../../../../contexts/EmployeeContext'
import { SetStatusModal } from './Modal/SetStatusModal'
import { statusAPI } from 'API/Employees/status'
import {
  GroupedOptionType,
  formatAllowedStatusesTransitions,
} from 'utils/helpers/EmployeeProfile/formatAllowedStatusesTransitions'
import Button from '@atlaskit/button/new'
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import { AllowedStatusesTransitions, EmployeeStatuses } from '../../../../../../interfaces/employee/statuses.interface'
import { RolesEnum } from 'shared'

interface EmployeeStatusHistoryProps {
  setIsCurStatusInactive?: React.Dispatch<React.SetStateAction<boolean>>
  employee: EmployeeFromApi
  currentJobInfo: CurrentJobInfoFromApi | null
  onDataUpdate: () => void
  triggerParentUpdate: () => void
  updateCurrentJobInfo: () => void
  setIsHired: (isHired: boolean) => void
}

type EmployeeStatus = {
  id: string
  statusName: string
  dateStart: string
  dateEnd: string
}

export const EmployeeStatusHistory: FC<EmployeeStatusHistoryProps> = (props) => {
  const { employee: employeeContext } = useEmployee()
  assertEmployee(employeeContext)

  const [lastStatusName, setLastStatusName] = useState<string>('')
  const [lastStatusStartDate, setLastStatusStartDate] = useState<string>('')
  const [lastStatusId, setLastStatusId] = useState<string>('')
  const [lastOriginalStatusId, setLastOriginalStatusId] = useState<string>('')
  const [lastStatusType, setLastStatusType] = useState<boolean>(false)
  const [lastStatusArchived, setLastStatusArchived] = useState<boolean>(false)
  const [previousStatusId, setPreviousStatusId] = useState<string>('')
  const [previousStatusStartDate, setPreviousStatusStartDate] = useState<string>('')
  const [fOptsAllowedStatusesTransitions, setfOptsAllowedStatusesTransitions] = useState<GroupedOptionType[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [statusSetTrigger, setStatusSetTrigger] = useState(0)
  const [employeeStatuses, setEmployeeStatuses] = useState<EmployeeStatuses[]>([])

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await statusAPI.getUserStatuses(props.employee.id)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data: EmployeeStatuses[] = await response.json()
        if (data.length > 0) {
          const lastStatus = data[data.length - 1]
          const previousStatus = data.length > 1 ? data[data.length - 2] : null
          setLastStatusName(lastStatus.statusName)
          setPreviousStatusId(previousStatus ? previousStatus.id : lastStatus.id)

          const previousDate = new Date(previousStatus ? previousStatus.dateStart : lastStatus.dateStart)
          previousDate.setDate(previousDate.getDate() + 1)
          setPreviousStatusStartDate(previousDate.toISOString().split('T')[0])

          const lastStartDate = new Date(lastStatus.dateStart)
          lastStartDate.setDate(lastStartDate.getDate())
          setLastStatusStartDate(lastStartDate.toISOString().split('T')[0])
          setLastStatusId(lastStatus.id)
          setLastOriginalStatusId(lastStatus.statusId)

          setLastStatusType(lastStatus.isActive)
          setLastStatusArchived(lastStatus.isArchived)
          if (props.setIsCurStatusInactive) {
            props.setIsCurStatusInactive(lastStatus.isActive)
          }
          data.sort((a, b) => {
            const dateA = new Date(a.dateStart)
            const dateB = new Date(b.dateStart)
            return dateB.getTime() - dateA.getTime()
          })
        }
        setEmployeeStatuses(data)
      } catch (error) {
        console.error('Error fetching statuses:', error)
      }
    }

    fetchStatuses()
  }, [props.employee.id, statusSetTrigger])

  function triggerEmployeeStatusesUpdate() {
    props.triggerParentUpdate()
  }
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await statusAPI.getUserAllowedStatusesTransitions(props.employee.id)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data: AllowedStatusesTransitions[] = await response.json()
        const formattedOptions = formatAllowedStatusesTransitions(data)
        setfOptsAllowedStatusesTransitions(formattedOptions)
      } catch (error) {
        console.error('Error fetching statuses:', error)
      }
    }

    fetchStatuses()
  }, [props.employee.id, statusSetTrigger])

  const handleStatusUpdate = () => {
    setStatusSetTrigger((old) => old + 1)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = useCallback(() => setIsModalOpen(false), [])

  return (
    <div className="info">
      <div className="info-header">
        <h3>Employee status history</h3>
      </div>
      <div className="info-body status-history">
        <div className="profile-field-group">
          <div className="profile-field">
            <div className="profile-field-title">
              <div>Employee status</div>
            </div>
            <div className="profile-field-value">
              {employeeContext.roles.includes(RolesEnum.ROLE_HR) && (
                <>
                  <Button iconBefore={EditorAddIcon} appearance="subtle" spacing="compact" onClick={() => openModal()}>
                    Set status
                  </Button>
                  <SetStatusModal
                    dateFrom={lastStatusStartDate}
                    employeeId={props.employee.id}
                    onStatusUpdate={handleStatusUpdate}
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                    options={fOptsAllowedStatusesTransitions}
                    currentStatusIsActive={lastStatusType}
                    currentStatusArchived={lastStatusArchived}
                    currentStatusId={lastStatusId}
                    currentOriginalStatusId={lastOriginalStatusId}
                    mode="add"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <EmployeeStatus
          id="new-status"
          handleStatusUpdate={handleStatusUpdate}
          previousStatusId={previousStatusId}
          previousStatusStartDate={previousStatusStartDate}
          index={employeeStatuses.length}
          employeeStatuses={employeeStatuses}
          employee={props.employee}
          lastStatusName={lastStatusName}
          lastStatusId={lastStatusId}
          lastOriginalStatusId={lastOriginalStatusId}
          lastStatusStartDate={lastStatusStartDate}
          triggerParentUpdate={triggerEmployeeStatusesUpdate}
        />
      </div>
    </div>
  )
}
