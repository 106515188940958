import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../constants'
import { GetMilitaryRegistrationQuery, MilitaryRegistration, UpdateMilitaryRegistrationPayload } from './types'

export const militaryRegistrationApi = createApi({
  reducerPath: 'militaryRegistrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/employees`,
  }),
  tagTypes: ['militaryRegistration'],
  endpoints: (build) => ({
    getMilitaryRegistration: build.query<MilitaryRegistration, GetMilitaryRegistrationQuery>({
      query: ({ employeeId }: GetMilitaryRegistrationQuery) => ({
        url: `${employeeId}/military-registration-data
`,
      }),

      providesTags: [{ type: 'militaryRegistration' }],
    }),

    updateMilitaryRegistration: build.mutation<MilitaryRegistration, UpdateMilitaryRegistrationPayload>({
      query: ({
        employeeId,
        status,
        attachedTcrss,
        tcrssByRegistration,
        basisDeferment,
        dataClarified,
        documentLink,
      }) => ({
        url: `${employeeId}/military-registration-data`,
        method: 'PUT',
        body: {
          status,
          attachedTcrss,
          tcrssByRegistration,
          basisDeferment,
          dataClarified,
          documentLink,
        },
      }),

      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'militaryRegistration' }]
        }
        return []
      },
    }),
  }),
})

export const { useGetMilitaryRegistrationQuery, useUpdateMilitaryRegistrationMutation } = militaryRegistrationApi
