/* eslint-disable no-console */
import { forwardRef } from 'react'
import { Input, InputRef, Tooltip } from 'antd'
import { InlineInputContainerStyled, TooltipContentStyled } from './styles'
//TODO change on antd icons
import CheckIcon from '@atlaskit/icon/glyph/check'
import CloseIcon from '@atlaskit/icon/glyph/cross'
import { CloseCircleFilled } from '@ant-design/icons'
import { InlineEditInputProps } from './types'

export const InlineEditInput = forwardRef(
  (
    {
      value,
      name,
      placeholder,
      error,
      editing,
      isSubmitting,
      hasEditAccess,
      onChange,
      onBlur,
      onEditCancel,
      onEditStart,
    }: InlineEditInputProps,
    ref,
  ) => {
    const handleSave = () => {
      if (error) {
        return
      }
      onBlur?.()
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.relatedTarget?.id === 'stop-edit' || e.relatedTarget?.id === 'save-edit') {
        return
      }
      if (error) {
        return
      }
      onBlur?.()
    }

    return (
      <InlineInputContainerStyled $editing={editing} $hasEditAccess={hasEditAccess} $value={value}>
        {editing ? (
          <div className="inline-edit-input-wrapper">
            <Input
              className="inline-edit-input"
              ref={ref as React.RefObject<InputRef>}
              autoFocus
              value={value ?? undefined}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeholder}
              onBlur={handleBlur}
              status={error ? 'error' : undefined}
              suffix={
                error && (
                  <Tooltip open color="white" title={<TooltipContentStyled>{error}</TooltipContentStyled>}>
                    <CloseCircleFilled />
                  </Tooltip>
                )
              }
              name={name}
            />
            <div className="inline-edit-input-buttons-block">
              <button
                type="submit"
                className="inline-edit-input-button"
                disabled={isSubmitting}
                id="save-edit"
                onClick={() => {
                  handleSave()
                  onEditCancel()
                }}
              >
                <CheckIcon label="Save" size="small" />
              </button>
              <button
                type="button"
                className="inline-edit-input-button"
                disabled={isSubmitting}
                id="stop-edit"
                onClick={() => onEditCancel(true)}
              >
                <CloseIcon label="Close" size="small" />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="inline-edit-input-read-view"
            onClick={() => {
              if (hasEditAccess) {
                onEditStart()
              }
              return
            }}
          >
            <span>{value || placeholder}</span>
          </div>
        )}
      </InlineInputContainerStyled>
    )
  },
)
