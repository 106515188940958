import styled from 'styled-components'

export const ConflictsPayrollListStyles = styled.div`
  .title {
    position: relative;
    margin-bottom: 24px;
    padding-left: 32px;
    margin-top: -10px;

    .anticon-close-circle {
      position: absolute;
      top: 4px;
      left: 0;
      margin: 0;
      font-size: 24px;

      svg {
        color: rgba(255, 77, 79, 1);
      }
    }

    h4 {
      color: rgba(0, 0, 0, 0.88);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }
  }

  .payrolls-list {
    max-height: 365px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    margin-bottom: 24px;

    .ant-collapse {
      border: none;
      background: transparent;

      .ant-collapse-item > .ant-collapse-header {
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 0;

        .ant-collapse-expand-icon {
          padding: 0;
        }

        .ant-collapse-arrow {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .employee-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .employee-card {
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-avatar-string {
      font-size: 14px;
    }

    .employee {
      display: flex;
      flex-direction: column;

      &-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 242px;
        overflow: hidden;
        line-height: 16px;
      }

      &-nickname {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 16px;
      }
    }
  }

  .links-list {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    a {
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;

      &:hover {
        text-decoration: none;
      }
    }
  }
`
