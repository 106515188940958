import styled from 'styled-components'

export const InlineInputContainerStyled = styled.div<{
  $editing: boolean
  $hasEditAccess?: boolean
  $value?: string | null
}>`
  max-width: 400px;
  width: 100%;
  color: ${({ $value: value }) => (value ? '#626f86' : '#00000040')};
  &:hover {
    border-color: ${({ $editing: editing }) => (editing ? '#1677ff' : 'rgba(9, 30, 66, 0.14)')};
    background: ${({ $editing: editing, $hasEditAccess: hasEditAccess }) =>
      editing || !hasEditAccess ? 'unset' : 'rgba(9, 30, 66, 0.04)'};
  }

  & .inline-edit-input {
    border-radius: 3px;
    outline: none;
    border-width: 2px;
    outline: none;
    padding: 4px 8px;

    &:hover {
      background: #f7f8f9;
      border-color: rgba(9, 30, 66, 0.14);
    }

    &:focus {
      border-width: 2px;
      border-color: #1677ff;
      box-shadow: unset;
      background: #fff;
    }

    &-wrapper {
      position: relative;
    }

    &-buttons-block {
      position: absolute;
      display: flex;
      inset-block-start: 100%;
      inset-inline-end: 0;
      gap: 4px;
      max-width: 68px;
      z-index: 10;
      background: #fff;
    }

    &-button {
      width: 32px;
      height: 32px;
      border-radius: 3px;
      border: 1px solid #dfe1e6;
      background: transparent;
      outline: 0;
      color: #44546f;
      box-shadow:
        0 4px 8px -2px rgba(9, 30, 66, 0.25),
        0 0 1px rgba(9, 30, 66, 0.31);
      margin-left: 0 !important;
      appearance: none;
      border: none;
      cursor: pointer;

      &:hover {
        background: #f1f2f4;
      }
    }

    &-read-view {
      border: 2px solid transparent;
      padding: 4px 8px;
      border-radius: 3px;
      width: 100%;
      word-break: break-word;
      &:hover {
        border-color: ${({ $hasEditAccess: hasEditAccess }) =>
          hasEditAccess ? 'rgba(9, 30, 66, 0.14)' : 'transparent'};
      }
    }
  }
`

//This styled div should be separated because tooltip use portal
export const TooltipContentStyled = styled.div`
  color: #44546f;
`
