import { useCallback, useMemo } from 'react'
import { useProfile } from '../../../../context'
import { useGetBonusPenaltyDataByEmployeeIdQuery } from 'services/api'
import { BonusPenaltyFormModal } from 'components'
import { hasActiveFilter } from 'utils/helpers/has-active-filters'
import { openBonusPenaltyModal } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks'
import { TableBox } from '../table-box'
import { Table, Filters } from './components'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'

export const BonusPenaltyTable = () => {
  const { idOpenProfile, profile, isCurrentUserProfile, isManagerOpenProfile } = useProfile()
  const { employee } = useEmployee()

  const dispatch = useAppDispatch()

  const { tableState, tableFilters } = useAppSelector((state) => state.employeeBonusPenaltyTable)
  const { isOpen } = useAppSelector((state) => state.bonusPenaltyModal)

  const { effectiveDate, statuses } = tableFilters

  const {
    data: bonusesPenaltiesDate = { items: [], total: 0 },
    isLoading,
    isFetching,
  } = useGetBonusPenaltyDataByEmployeeIdQuery({ ...tableState, ...tableFilters, employeeId: idOpenProfile })

  const onOpenCreateRequestModal = useCallback(() => dispatch(openBonusPenaltyModal()), [])

  const hasActiveFilters = useMemo(() => hasActiveFilter(tableFilters), [effectiveDate, statuses])

  return (
    <>
      <TableBox
        createRequestBtnAccess={
          isCurrentUserProfile || isManagerOpenProfile || employee?.roles.includes(RolesEnum.ROLE_FINANCIAL_MANAGER)
        }
        isLoadingTable={isLoading}
        tableTitle="Bonuses&Penalties history"
        skeleton={{ filtersCount: 2, colCount: 5, rowCount: 6 }}
        onClickAddBtn={onOpenCreateRequestModal}
        showTable={Boolean(bonusesPenaltiesDate.total)}
        tableComponent={<Table isLoading={isFetching} sourceData={bonusesPenaltiesDate} />}
        tableFiltersComponent={<Filters />}
        showTableFilters={hasActiveFilters}
      />
      {isOpen && <BonusPenaltyFormModal createFromProfile employee={profile} />}
    </>
  )
}
