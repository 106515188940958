import { useForm } from 'react-hook-form'
import { MilitaryReservationFormField, MilitaryReservationFormValues } from './types'
import { defaultValues } from './constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './schema'
import isEqual from 'lodash/isEqual'
import { useGetMilitaryReservationQuery, useUpdateMilitaryReservationMutation } from 'services/api'
import { useAppDispatch } from 'hooks'
import { useState } from 'react'
import { serializeMilitaryReservation } from './utils'
import { triggerNotification } from 'store'

export const useMilitaryReservationForm = (employeeId: string) => {
  const dispatch = useAppDispatch()
  const { data: militaryReservationResponse, isLoading } = useGetMilitaryReservationQuery({ employeeId })
  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({})
  const [updateMilitaryReservation] = useUpdateMilitaryReservationMutation()
  const methods = useForm<MilitaryReservationFormValues>({
    defaultValues,
    values: militaryReservationResponse,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const stopEditing = async (field: MilitaryReservationFormField, forceExit: boolean = false) => {
    if (forceExit) {
      methods.reset({ ...(militaryReservationResponse ?? defaultValues) })
      setEditingFields((prev) => ({ ...prev, [field]: false }))
      return
    }

    const isValid = await methods.trigger(field)

    if (!isValid) {
      setEditingFields((prev) => ({ ...prev, [field]: true }))
      return
    }

    setEditingFields((prev) => ({ ...prev, [field]: !!methods.formState.errors[field]?.message || false }))
  }

  const startEditing = (field: MilitaryReservationFormField) => {
    const activeFieldWithError = (Object.keys(editingFields) as MilitaryReservationFormField[]).find(
      (key) => editingFields[key] && methods.formState.errors[key],
    )

    if (activeFieldWithError) {
      setEditingFields((prev) => ({ ...prev, [activeFieldWithError]: true }))
      return
    }
    if (field) methods.clearErrors(field)
    setEditingFields({ [field]: true })
  }

  const onSubmit = async (data: MilitaryReservationFormValues) => {
    try {
      if (isEqual(data, militaryReservationResponse || defaultValues)) {
        return
      }
      const seraialized = serializeMilitaryReservation(data)
      await updateMilitaryReservation({ employeeId, ...seraialized }).unwrap()
    } catch (error) {
      dispatch(triggerNotification({ type: 'error', message: 'Error occurred while editing military reservation' }))
      methods.reset(militaryReservationResponse || defaultValues)
      console.error(error)
    } finally {
      setEditingFields({})
    }
  }
  function handleBlur() {
    methods.handleSubmit(onSubmit)()
  }

  return {
    isLoading,
    editingFields,
    methods,
    onSubmit,
    handleBlur,
    startEditing,
    stopEditing,
  }
}
