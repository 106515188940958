import { Tabs } from 'antd'
import styled from 'styled-components'

const { TabPane } = Tabs

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: fit-content;
    margin-bottom: 24px;
  }
  .ant-tabs-tab-btn {
    display: flex;
    justify-content: justify;
    align-items: center;
    padding: 1px 1px 8px 0 !important;
    gap: 6px;
  }

  .ant-tabs-tab {
    padding: 0;
    font-weight: 600;
    color: #44546f;
  }

  .ant-tabs-ink-bar {
    background-color: ${({ activeKey }) => (activeKey === 'personality-analysis-by-ai' ? '#531dab' : '#096dd9')};
    height: 4px;
    border-radius: 4px;
  }
`

export const StyledTab = styled(TabPane)``

export const DropDownButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`
