import { Button } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { openDeleteSystemCurrencyModal } from 'store'
import { useAppDispatch } from 'hooks'
import { TableActionProps } from './types'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'
import { TableActionsStyles } from './styles'

export const TableAction = (props: TableActionProps) => {
  const { record } = props
  const dispatch = useAppDispatch()
  const handleOpenDeleteModal = () => dispatch(openDeleteSystemCurrencyModal({ payload: record }))
  const { employee } = useEmployee()
  const roles = [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_FINANCIAL_MANAGER]
  const isEditRole = roles.some((role) => employee?.roles.includes(role))

  return (
    <TableActionsStyles>
      {isEditRole && <Button size="small" icon={<DeleteFilled />} onClick={handleOpenDeleteModal} />}
    </TableActionsStyles>
  )
}
