import { MilitaryReservationFormValues } from './types'

export const serializeMilitaryReservation = (values: MilitaryReservationFormValues) => ({
  employmentForm: values.employmentForm?.trim() ?? null,
  reservation: values.reservation ?? null,
  dateEnd: values.dateEnd?.trim() ?? null,
  status: values.status?.trim() ?? null,
  criticality: values.criticality?.trim() ?? null,
  notes: values.notes?.trim() ?? null,
})
