import { SelectValue } from 'shared/Common/data/SelectValue'
import { MilitaryReservationFormValues } from './types'

export const defaultValues: MilitaryReservationFormValues = {
  employmentForm: null,
  reservation: null,
  dateEnd: null,
  status: null,
  criticality: null,
  notes: null,
}
//TODO in future remove this. Data should be fetched from the server
export const reservationStatusOptions: SelectValue[] = [
  {
    value: 'Not required (excluded, conscript, abroad, deferment, etc.)',
    label: 'Not required (excluded, conscript, abroad, deferment, etc.)',
  },
  {
    value:
      'Cannot be placed in military reserve (refusal to provide information, no information, not registered, etc.)',
    label:
      'Cannot be placed in military reserve (refusal to provide information, no information, not registered, etc.)',
  },
  {
    value: 'Subject to military reservation (already reserved, in the process of reservation, etc.)',
    label: 'Subject to military reservation (already reserved, in the process of reservation, etc.)',
  },
]
//TODO in future remove this. Data should be fetched from the server
export const reservationCriticalityOptions: SelectValue[] = [
  { value: 'Super critical', label: 'Super critical' },
  { value: 'Important', label: 'Important' },
  { value: 'Normal', label: 'Normal' },
  { value: 'Low', label: 'Low' },
]

export const radioGroupOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
