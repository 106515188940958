// TODO: fix eslint errors
/* eslint-disable */
import { FC, useState, useEffect } from 'react'
import { RequestTimeOffModal } from 'components'
import RandomPhraseWidget from './Widgets/RandomPhrases/RandomPhrasesWidget'
import LinksWidget from './Widgets/Links/LinksWidget'
import Anniversaries from './Widgets/Celebrations/Anniversaries'
import Birthdays from './Widgets/Celebrations/Birthdays'
import AbsentWidget from './Widgets/Absent/AbsentWidget'
import RequestTimeOff from './Widgets/RequestTimeOff/RequestTimeOff'
import EventCalendar from './Widgets/Calendar/EventCalendar'
import AnnouncementBlock from './Widgets/Announcement/AnnouncementBlock'
import OutgoingEmployeesWidget from './Widgets/EmployeeStatus/OutgoingEmployees/OutgoingEmployeesWidget'
import NewbiesWidget from './Widgets/EmployeeStatus/Newbies/NewbiesWidget'
import { EMPLOYEES } from 'utils/constants/api'
import { useEmployee, assertEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'
import { TSubordinates } from 'types/subordinates'
import { fetchTimeOffBalanceAPI } from 'API/profile/timeOffBalance/timeOffBalance'
import { StyledHomePage, StyledHomePageLeft, StyledHomePageRight } from './styles'

export const Main: FC = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)

  const [subordinates, setSubordinates] = useState<TSubordinates[]>([])
  let roleHR: boolean = false
  if (employee) {
    roleHR = employee.roles.includes(RolesEnum.ROLE_HR)
  }
  const isManager = subordinates.length > 0

  const [loadingBarProgress, setLoadingBarProgress] = useState<number>(0)
  const [createRequestModal, setCreateRequestModal] = useState<boolean>(false)

  const [timeOffBalance, setTimeOffBalance] = useState<
    {
      timeOffPolicyId: string
      timeOffPolicyName: string
      quantity: number
      typeIcon: string
    }[]
  >([])

  useEffect(() => {
    fetchTimeOffBalanceAPI(employee.id).then((response) => {
      setTimeOffBalance(response)
    })
  }, [employee])

  useEffect(() => {
    const rootElement = document.getElementById('root')

    if (rootElement) {
      rootElement.scrollTo(0, 0)
    }

    fetch(`${EMPLOYEES}/employee/${employee.id}/subordinate-direct-employees`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSubordinates(data)
      })
  }, [])

  const handleRequestModal = (isOpen: boolean) => {
    setCreateRequestModal(isOpen)
  }

  return (
    <>
      <StyledHomePage>
        <StyledHomePageLeft>
          <RandomPhraseWidget />
          <AbsentWidget
            setLoadingBarProgress={setLoadingBarProgress}
            requestModal={handleRequestModal}
            employee={employee}
          />
          <NewbiesWidget employee={employee} />
          <Anniversaries employee={employee} />
          <Birthdays employee={employee} />
          <OutgoingEmployeesWidget employee={employee} />
        </StyledHomePageLeft>

        <StyledHomePageRight>
          <AnnouncementBlock />
          <RequestTimeOff
            timeOffBalance={timeOffBalance}
            openModal={(arg) => {
              setCreateRequestModal(arg)
            }}
          />
          <EventCalendar employee={employee} />
          <LinksWidget />
        </StyledHomePageRight>

        {createRequestModal && (
          <RequestTimeOffModal
            isModalOpen={createRequestModal}
            closeModal={() => {
              setCreateRequestModal(false)
            }}
            isManager={isManager}
            isHR={roleHR}
            viewer={employee}
            subordinates={subordinates}
            updateData={() => {
              fetchTimeOffBalanceAPI(employee.id).then((response) => {
                setTimeOffBalance(response)
              })
            }}
          />
        )}
      </StyledHomePage>
    </>
  )
}
