import { Form, Radio } from 'antd'
import { MilitaryReservationFormStyled } from './styles'
import { useMilitaryReservationForm } from './use-military-reservation-form'
import { BlockInfoItem, BlockInfoSkeletonItem } from 'components/Profile/BlockInfo'
import { Controller } from 'react-hook-form'
import { InlineEditInput } from 'components/Profile/components/inline-edit-input'
import { radioGroupOptions, reservationCriticalityOptions, reservationStatusOptions } from './constants'
import { FormDatepicker } from 'components'
import { MilitaryReservationFormValues } from './types'
import { OfficialInformationSelect } from 'components/Profile/components/official-information-select/official-information-select'

export const MilitaryReservationForm = ({
  employeeId,
  hasEditAccess,
}: {
  employeeId: string
  hasEditAccess: boolean
}) => {
  const { isLoading, editingFields, methods, onSubmit, handleBlur, startEditing, stopEditing } =
    useMilitaryReservationForm(employeeId)
  return (
    <MilitaryReservationFormStyled>
      <div className="military-reservation-title-block">
        <h4 className="military-reservation-title">Military reservation</h4>
      </div>
      {isLoading ? (
        <>
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
          <BlockInfoSkeletonItem />
        </>
      ) : (
        <Form onFinish={methods.handleSubmit(onSubmit)}>
          <Controller
            name="employmentForm"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem title="Employment form" className="military-reservation-block-info">
                <div className="military-reservation-form-field-wrapper">
                  <InlineEditInput
                    {...field}
                    hasEditAccess={hasEditAccess}
                    placeholder="e. g. LLC, SP"
                    error={methods.formState.errors.employmentForm?.message}
                    onBlur={handleBlur}
                    editing={!!editingFields[field.name]}
                    onEditStart={() => startEditing(field.name)}
                    onEditCancel={(forceExit?: boolean) => {
                      stopEditing(field.name, forceExit)
                    }}
                    isSubmitting={methods.formState.isSubmitting}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="reservation"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem title="Is there a military reservation?" className="military-reservation-block-info">
                <div className="military-reservation-form-field-wrapper">
                  <Radio.Group
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      handleBlur()
                    }}
                    disabled={!hasEditAccess}
                    options={radioGroupOptions}
                    className="military-reservation-form-radio-group"
                  />
                </div>
              </BlockInfoItem>
            )}
          />

          <BlockInfoItem
            title="End of military reservation (if applicable)"
            className="military-reservation-block-info"
          >
            <div className="military-reservation-form-field-wrapper">
              <FormDatepicker<MilitaryReservationFormValues>
                name="dateEnd"
                placeholder="Select date"
                control={methods.control}
                className="military-reservation-datepicker"
                allowClear
                onCustomChange={handleBlur}
                disabled={!hasEditAccess}
              />
            </div>
          </BlockInfoItem>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem
                title={
                  <div
                    style={{
                      width: 271, //TODO: remove hardcoded width, and rewrtite BlockInfoItem from table to flex
                    }}
                  >
                    Military reservation status
                  </div>
                }
                className="military-reservation-block-info"
              >
                <div className="military-reservation-form-field-wrapper">
                  <OfficialInformationSelect
                    {...field}
                    onSelect={handleBlur}
                    disabled={!hasEditAccess}
                    placeholder="–"
                    optionFilterProp="label"
                    options={reservationStatusOptions}
                    allowClear
                    onClear={handleBlur}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="criticality"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem
                title={
                  <div>
                    Military reservation criticality <br />
                    (if necessary)
                  </div>
                }
                className="military-reservation-block-info"
              >
                <div className="military-reservation-form-field-wrapper">
                  <OfficialInformationSelect
                    {...field}
                    onSelect={handleBlur}
                    disabled={!hasEditAccess}
                    placeholder="–"
                    optionFilterProp="label"
                    options={reservationCriticalityOptions}
                    allowClear
                    onClear={handleBlur}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
          <Controller
            name="notes"
            control={methods.control}
            render={({ field }) => (
              <BlockInfoItem title="Notes" className="military-reservation-block-info">
                <div className="military-reservation-form-field-wrapper">
                  <InlineEditInput
                    {...field}
                    hasEditAccess={hasEditAccess}
                    placeholder="–"
                    error={methods.formState.errors.notes?.message}
                    onBlur={handleBlur}
                    editing={!!editingFields[field.name]}
                    onEditStart={() => startEditing(field.name)}
                    onEditCancel={(forceExit?: boolean) => {
                      stopEditing(field.name, forceExit)
                    }}
                    isSubmitting={methods.formState.isSubmitting}
                  />
                </div>
              </BlockInfoItem>
            )}
          />
        </Form>
      )}
    </MilitaryReservationFormStyled>
  )
}
