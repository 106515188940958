import { Tabs } from 'antd'
import { resetSelectedPayrolls } from 'store'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { payrollTabsConfig } from './constants'
import { PayrollStyles } from './styles'

export const Payroll = () => {
  const dispatch = useAppDispatch()
  const payrollType = useAppSelector((state) => state.payrollType)
  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)

  useEffect(() => {
    if (selectedPayrollsData.selectedPayrolls.length) {
      dispatch(resetSelectedPayrolls())
    }
  }, [])

  return (
    <PayrollStyles>
      <Tabs activeKey={payrollType} items={payrollTabsConfig} />
    </PayrollStyles>
  )
}
