import { useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { orderDirectionEnumLabel, RolesEnum } from 'shared'
import { triggerNotification } from 'store'
import clsx from 'clsx'
import isArray from 'lodash/isArray'
import { Table, Input, Select } from 'antd'
import { Button, DropDownIcon } from 'components'
import type { GetProps, TableProps } from 'antd'
import { NoResults } from 'components/UI/table'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  useGetCurrenciesQuery,
  useAddSystemCurrencyMutation,
  useGetSystemCurrenciesQuery,
  currenciesApi,
} from 'services/api'
import { useEmployee } from 'contexts/EmployeeContext'
import { Currency, SystemCurrency } from 'models'
import { TablePaginationData } from 'types'
import { GetCurrenciesPayload, CurrenciesSortBy } from 'services/api/currencies/types'
import { DeleteModal } from './components'
import { defaultCurrenciesTableState, tableColumnsMap } from './constants'
import { tableColumns } from './table-columns'
import { CurrenciesStyles } from './styles'

type SearchProps = GetProps<typeof Input.Search>
const { Search } = Input

export const Currencies = () => {
  const dispatch = useAppDispatch()

  const [tableQuery, setTableQuery] = useState<GetCurrenciesPayload>(defaultCurrenciesTableState)
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null)

  const isOpenModal = useAppSelector((state) => state.deleteSystemCurrencyModal.isOpen)
  const {
    data: systemCurrencyList = [],
    isLoading: isLoadingSystemCurrency,
    isFetching: isFetchingSystemCurrency,
  } = useGetSystemCurrenciesQuery(tableQuery)
  const [addSystemCurrency] = useAddSystemCurrencyMutation()
  const { data: currenciesList, isLoading: isLoadingCurrency } = useGetCurrenciesQuery()
  const { employee } = useEmployee()
  const roles = [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_FINANCIAL_MANAGER]
  const isEditRole = roles.some((role) => employee?.roles.includes(role))

  const currenciesSelectOptions = useMemo(() => {
    if (!currenciesList) return []

    return currenciesList.map((item: Currency) => ({
      label: `${item.code} - ${item.name}`,
      value: item.id,
    }))
  }, [currenciesList])

  const onSearchHandler: SearchProps['onSearch'] = (value) => {
    setTableQuery({
      ...tableQuery,
      search: value.trim(),
    })
  }

  const onSearchChange: SearchProps['onChange'] = (e) => {
    if (!e.target.value) {
      setTableQuery(defaultCurrenciesTableState)
    }
  }

  const onTableChange: TableProps<SystemCurrency>['onChange'] = (_pagination, _filters, sorter) => {
    setTableQuery({
      ...tableQuery,
      order:
        !isArray(sorter) && sorter.order ? orderDirectionEnumLabel[sorter.order] : defaultCurrenciesTableState.order,
      sort:
        !isArray(sorter) && sorter.field && sorter.order
          ? (sorter.field as TablePaginationData<CurrenciesSortBy>['sort'])
          : defaultCurrenciesTableState.sort,
    })
  }

  const onCurrencySelect = async (value: string) => {
    const newQuery: GetCurrenciesPayload = {
      currencyId: value,
    }

    try {
      await addSystemCurrency(newQuery).unwrap()
      setSelectedCurrency(null)

      dispatch(currenciesApi.util.invalidateTags(['currencies']))

      dispatch(
        triggerNotification({
          type: 'success',
          message: "You've added a currency.",
        }),
      )
    } catch (error: any) {
      if (error.status === 422) {
        dispatch(
          triggerNotification({
            type: 'error',
            message: 'Currency already have been added.',
          }),
        )
      }
      console.error({ error })
    } finally {
      setIsSelectOpen(false)
    }
  }

  const showSelectHandler = () => {
    setIsSelectOpen(true)
  }

  return (
    <CurrenciesStyles>
      {isLoadingSystemCurrency ? (
        <SkeletonTable colCount={4} rowCount={10} isHeaderButton={false} isSubtitle />
      ) : (
        <>
          <h3 className="currencies-title">Currencies</h3>
          <Search
            allowClear
            className="currencies-search"
            placeholder="Search"
            onSearch={onSearchHandler}
            onChange={onSearchChange}
            onClear={() => setTableQuery(defaultCurrenciesTableState)}
            loading={isFetchingSystemCurrency}
          />
          {tableQuery.search && !systemCurrencyList.length ? (
            <NoResults />
          ) : (
            <>
              <Table<SystemCurrency>
                columns={tableColumns}
                dataSource={systemCurrencyList}
                className="currencies-table"
                size="middle"
                pagination={false}
                onChange={onTableChange}
                loading={isFetchingSystemCurrency}
                rowKey={tableColumnsMap.id}
              />
              <div className="currencies-add">
                <div className={clsx('currencies-add__select', { 'is-open': isSelectOpen })}>
                  <Select
                    showSearch
                    value={selectedCurrency}
                    placeholder="Select currency"
                    options={currenciesSelectOptions}
                    optionFilterProp="label"
                    onSelect={onCurrencySelect}
                    suffixIcon={
                      isLoadingCurrency ? <LoadingOutlined className="currencies-add__loading" /> : <DropDownIcon />
                    }
                  />
                </div>
                {isEditRole && (
                  <div className="currencies-add__btn">
                    <Button icon={<PlusOutlined />} type="text" onClick={showSelectHandler}>
                      Add currency
                    </Button>
                  </div>
                )}
              </div>
              {isOpenModal && <DeleteModal />}
            </>
          )}
        </>
      )}
    </CurrenciesStyles>
  )
}
