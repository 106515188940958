import { Button } from 'antd'
import styled from 'styled-components'

export const StyledIconButton = styled(Button)`
  width: 24px;
  height: 24px;
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  &:hover {
    background: #e9f2ff !important;
  }
`
