import dayjs from 'dayjs'
import { OrderEnum } from 'shared'
import { dateFormatDB } from 'utils/helpers/formatted-date'
import { PayrollTableFilters, PayrollTableState } from './types'

export const defaultPayrollTableState: PayrollTableState = {
  page: 1,
  limit: 10,
  sort: 'startDate',
  order: OrderEnum.DESC,
}

export const defaultPayrollTableFilters: PayrollTableFilters = {
  closed: {
    periodDates: [
      dayjs(`${dayjs().year()}-01-01`).format(dateFormatDB),
      dayjs().subtract(1, 'day').format(dateFormatDB),
    ],
  },
  active: {},
}
