import { Radio, RadioChangeEvent } from 'antd'
import { useAppSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { setPayrollType } from 'store'
import { tabButtonsOptions } from './constants'

export const TabButtons = () => {
  const dispatch = useDispatch()
  const payrollType = useAppSelector((state) => state.payrollType)

  const handleChangePayrollType = (e: RadioChangeEvent) => {
    dispatch(setPayrollType(e.target.value))
  }

  return (
    <Radio.Group
      block
      options={tabButtonsOptions}
      value={payrollType}
      onChange={handleChangePayrollType}
      optionType="button"
    />
  )
}
