import { Form, Modal, Popover } from 'antd'
import { useId, useState } from 'react'
import clsx from 'clsx'
import { Button, FormDatepicker, InfoIcon } from 'components'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CreateClosedPayrollsErrorResponse,
  CreateClosedPayrollsErrorResponseBody,
  CreateClosedPayrollsPayload,
  useCreateClosedPayrollMutation,
} from 'services/api'
import { useAppDispatch, useAppSelector } from 'hooks'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { resetSelectedPayrolls, setClosePayrollModalOpen, triggerNotification, resetPayrollTableState } from 'store'
import { ConflictsPayrollList } from './components'
import { ClosePayrollModalStyles } from './styles'
import { ClosePayrollFormValues } from './types'
import { schema } from './schema'

export const ClosePayrollModal = () => {
  const [isActivePopover, setIsActivePopover] = useState(false)
  const [conflictsPayrollList, setConflictsPayrollList] = useState<CreateClosedPayrollsErrorResponse[]>([])
  const hasConflictsPayrollList = Boolean(conflictsPayrollList.length)

  const id = useId()

  const dispatch = useAppDispatch()

  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)

  const [createClosedPayrolls, { isLoading }] = useCreateClosedPayrollMutation()

  const { handleSubmit, control } = useForm<ClosePayrollFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      dateEnd: '',
    },
  })

  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current > dayjs().subtract(1, 'day')

  const handleCloseModal = () => {
    dispatch(setClosePayrollModalOpen(false))
    setConflictsPayrollList([])
  }

  const handleClosePayroll: SubmitHandler<ClosePayrollFormValues> = async ({ dateEnd }) => {
    const employeeIds = selectedPayrollsData.selectedPayrolls.map((payroll) => payroll.employeeId)

    // Temp solution
    const excludedIds = selectedPayrollsData.excludedIds.length
      ? selectedPayrollsData.excludedIds.map((item) => item.substring(0, item.lastIndexOf('-')))
      : []

    const closedCount = employeeIds.length || 'all'

    const payload: CreateClosedPayrollsPayload = {
      employeeIds,
      dateEnd,
      all: selectedPayrollsData.selectedAll,
      excludedIds,
    }

    try {
      await createClosedPayrolls(payload).unwrap()

      dispatch(
        triggerNotification({
          type: 'success',
          message: 'Successfully closed payroll period',
          description: `You’ve closed ${closedCount} payroll periods`,
        }),
      )

      if (selectedPayrollsData.selectedAll) {
        dispatch(resetPayrollTableState())
      }

      dispatch(resetSelectedPayrolls())
      handleCloseModal()
    } catch (error) {
      const typedError = error as CreateClosedPayrollsErrorResponseBody

      if (typedError.status === 409) {
        setConflictsPayrollList(typedError.data)
      } else {
        dispatch(
          triggerNotification({
            type: 'error',
            message: 'Something went wrong',
            description: 'Please reload the page and try again.',
          }),
        )
        // Need for testing
        console.error('Error creating closed payroll', error)
      }
    }
  }

  return (
    <Modal
      title={hasConflictsPayrollList ? null : 'Close payroll period'}
      open
      footer={null}
      onCancel={handleCloseModal}
      destroyOnClose
      width={400}
      maskClosable={false}
    >
      <ClosePayrollModalStyles id={id}>
        <p className="description">
          {hasConflictsPayrollList
            ? null
            : 'After closing payroll period, you will not be able to edit time offs, attendance log, rates, bonuses and penalties.'}
        </p>
        {hasConflictsPayrollList ? (
          <ConflictsPayrollList list={conflictsPayrollList} onClose={handleCloseModal} />
        ) : (
          <Form onFinish={handleSubmit(handleClosePayroll)} layout="vertical">
            <FormDatepicker<ClosePayrollFormValues>
              control={control}
              name="dateEnd"
              placeholder="Select date"
              label={
                <>
                  Payroll period end date
                  <Popover
                    placement="bottomLeft"
                    content={
                      <>
                        The payroll period start date is generated automatically <br />
                        and may vary by employee, it’s either their start date or <br />
                        the previous period’s end date plus one day.
                      </>
                    }
                    trigger="click"
                    arrow={false}
                    onOpenChange={(open) => setIsActivePopover(open)}
                    getPopupContainer={() => document.getElementById(id) as HTMLElement}
                  >
                    <InfoIcon className={clsx({ 'active-icon': isActivePopover })} />
                  </Popover>
                </>
              }
              disabledDate={disabledDate}
            />
            <div className="btn-group">
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Close payroll period
              </Button>
            </div>
          </Form>
        )}
      </ClosePayrollModalStyles>
    </Modal>
  )
}
