import { yupResolver } from '@hookform/resolvers/yup'
import isEqual from 'lodash/isEqual'
import { useAppDispatch } from 'hooks'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetOfficialInformationQuery, useUpdateOfficialInformationMutation } from 'services/api'
import { triggerNotification } from 'store'
import { serializeOfficialInformation } from '../utils'
import { validationSchema } from './schema'
import { OfficialInformationFormValues, OfficialInformationFormField } from './types'
import { defaultValues } from './constants'

export const useOfficialInformationForm = (employeeId: string) => {
  const dispatch = useAppDispatch()
  const { data: officialInfoResponse, isLoading } = useGetOfficialInformationQuery({ employeeId })
  const [updateOfficialInformation] = useUpdateOfficialInformationMutation()

  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({})
  const methods = useForm<OfficialInformationFormValues>({
    defaultValues,
    values: officialInfoResponse, // https://react-hook-form.com/docs/useform#values
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  })

  const stopEditing = async (field: OfficialInformationFormField, forceExit: boolean = false) => {
    if (forceExit) {
      methods.reset({ ...(officialInfoResponse ?? defaultValues) })
      setEditingFields((prev) => ({ ...prev, [field]: false }))
      return
    }

    const isValid = await methods.trigger(field)

    if (!isValid) {
      setEditingFields((prev) => ({ ...prev, [field]: true }))
      return
    }

    setEditingFields((prev) => ({ ...prev, [field]: !!methods.formState.errors[field]?.message || false }))
  }

  const startEditing = (field: OfficialInformationFormField) => {
    const activeFieldWithError = (Object.keys(editingFields) as OfficialInformationFormField[]).find(
      (key) => editingFields[key] && methods.formState.errors[key],
    )

    if (activeFieldWithError) {
      setEditingFields((prev) => ({ ...prev, [activeFieldWithError]: true }))
      return
    }
    if (field) methods.clearErrors(field)
    setEditingFields({ [field]: true })
  }

  const onSubmit = async (data: OfficialInformationFormValues) => {
    try {
      if (isEqual(data, officialInfoResponse || defaultValues)) {
        return
      }
      const seraialized = serializeOfficialInformation(data)
      await updateOfficialInformation({ employeeId, ...seraialized }).unwrap()
    } catch (error) {
      dispatch(triggerNotification({ type: 'error', message: 'Error occurred while editing official information' }))
      methods.reset(officialInfoResponse || defaultValues)
      console.error(error)
    } finally {
      setEditingFields({})
    }
  }

  function handleBlur() {
    methods.handleSubmit(onSubmit)()
  }

  return {
    methods,
    isLoading,
    editingFields,
    stopEditing,
    startEditing,
    handleBlur,
    onSubmit,
  }
}
