import { Tag, EmployeeTableItem } from 'components'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { bonusPenaltyColorMap, bonusPenaltyLabelMap } from 'shared/constants/employee-bonus-penalty'
import { BonusPenalty } from 'models'
import { tableColumnsMap } from './constants'
import { BonusPenaltyTableConfig } from './types'
import { TableAction, RequestInfo } from './components'

export const getTableColumns = (): BonusPenaltyTableConfig => {
  const config: BonusPenaltyTableConfig = [
    {
      title: 'Employee',
      width: '256px',
      dataIndex: tableColumnsMap.employeeFullName,
      render: (_, { employeeFullName, employeeAvatar, employeeNickname, employeeId }) => (
        <EmployeeTableItem
          employeeFullName={employeeFullName}
          employeeNickname={employeeNickname}
          employeeAvatar={employeeAvatar}
          employeeId={employeeId}
        />
      ),
      sorter: true,
    },
    {
      title: 'Creation date',
      width: '152px',
      dataIndex: tableColumnsMap.creationDate,
      render: (creationDate: BonusPenalty['creationDate']) => (
        <span>{getFormattedDate(creationDate, 'coreHRDateFormat')}</span>
      ),
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: tableColumnsMap.type,
      sorter: true,
      render: (type: BonusPenalty['type']) => (
        <Tag bordered={false} color={bonusPenaltyColorMap[type]}>
          {bonusPenaltyLabelMap[type]}
        </Tag>
      ),
      width: '152px',
    },
    {
      title: 'Amount',
      width: '152px',
      dataIndex: tableColumnsMap.amount,
      render: (amount: BonusPenalty['amount'], { currencySymbol }) => (
        <div>{currencyFormatter(amount, currencySymbol)}</div>
      ),
      sorter: true,
    },
    {
      title: 'Effective date',
      dataIndex: tableColumnsMap.effectiveDate,
      width: '152px',
      sorter: true,
      render: (effectiveDate: BonusPenalty['effectiveDate']) => (
        <span>{getFormattedDate(effectiveDate, 'coreHRDateFormat')}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: tableColumnsMap.status,
      width: '152px',
      sorter: true,
      render: (_: unknown, { status, actionEmployeeFullName, actionEmployeeId, actionEmployeeNickname }) => (
        <RequestInfo
          status={status}
          actionEmployeeFullName={actionEmployeeFullName}
          actionEmployeeId={actionEmployeeId}
          actionEmployeeNickname={actionEmployeeNickname}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: unknown, record: BonusPenalty) => <TableAction data={record} />,
    },
  ]

  return config
}
