import styled from 'styled-components'

export const StyledSelectContainer = styled.div`
  max-width: 400px;
  width: 100%;
  .official-information-select {
    width: 100%;

    & .ant-select-selector {
      padding: 0 8px;
      border-radius: 3px;
      border: 2px solid transparent;
      transition: border 0.3s;
      outline: 0;
      box-shadow: unset !important;
      color: #626f86;
    }

    & .ant-select-selection-placeholder {
      color: #00000040;
    }

    &.ant-select-disabled .ant-select-selector {
      background: transparent;
    }

    &:hover:not(.ant-select-open):not(.ant-select-disabled) .ant-select-selector {
      background: #f7f8f9;
      border: 2px solid #d9d9d9 !important;
    }

    &.ant-select-open .ant-select-selector {
      border: 2px solid #1890ff !important;
      box-shadow: unset !important;
    }

    &.ant-select-focused:not(.ant-select-open):not(:hover) .ant-select-selector {
      box-shadow: unset !important;
      border: 2px solid transparent !important;
    }
  }
`
//This styled div should be separated because OptionWrapper use portal
export const OptionWrapperStyled = styled.div`
  & .ant-select-item-option-content {
    white-space: normal;
    margin-bottom: 3px;
  }
`
