import styled from 'styled-components'

export const MilitaryReservationFormStyled = styled.div`
  margin-top: 32px;
  .military-reservation {
    &-title-block {
      display: flex;
      height: 28px;
      align-items: center;
      flex-shrink: 0;
      margin: 10px 0 16px 0;
      padding-bottom: 8px;
      border-bottom: 2px solid rgba(9, 30, 66, 0.06);
    }

    &-title {
      font-size: 14px;
      color: #172b4d;
      margin-bottom: 12px;
    }

    &-block-info {
      margin-bottom: 16px;
    }

    &-form-field-wrapper {
      display: flex;
      alignitems: center;
      gap: 8px;
    }

    &-form-radio-group {
      padding: 0 8px;

      & .ant-radio-wrapper {
        color: #626f86 !important;
      }
    }

    &-datepicker {
      max-width: 400px;
      width: 100%;
      margin-bottom: 0;
      border: 2px solid transparent;
      box-shadow: none;
      border-radius: 3px;
      color: #626f86;

      & .ant-picker-disabled {
        background: transparent !important;
      }

      &:hover:not(.ant-picker-focused):not(.ant-picker-disabled) .ant-picker {
        background: #f7f8f9;
        border: 2px solid #d9d9d9;
      }

      &.ant-picker-focused {
        border: 2px solid #1890ff !important;
        box-shadow: unset !important;
        background: #f7f8f9 !important;
      }

      &.ant-picker-focused:not(:hover) .ant-picker {
        box-shadow: unset !important;
        border: 2px solid transparent !important;
      }

      &:hover:is(.ant-picker-disabled) {
        border: 2px solid transparent !important;
      }
    }
  }
`
