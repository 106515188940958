import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  announcementAPI,
  locationsAPI,
  systemCurrencyApi,
  bonusPenaltyApi,
  timeOffLogAPI,
  employeeCompensationApi,
  employeeMgpHistoryApi,
  employeeRateHistoryApi,
  currenciesApi,
  activePayrollApi,
  closedPayrollApi,
  officialInformationApi,
  militaryRegistrationApi,
  militaryReservationApi,
} from 'services/api'
import {
  aiButtonReducer,
  announcementsReducer,
  locationsReducer,
  bonusPenaltyModalReducer,
  notificationsHandlerReducer,
  bonusPenaltyTableReducer,
  showEmployeeCompensationReducer,
  employeeMgpHistoryTableFiltersReducer,
  employeeRateHistoryTableFiltersReducer,
  mgpModalReducer,
  rateModalReducer,
  employeeBonusPenaltyTableReducer,
  deleteSystemCurrencyModalReducer,
  payrollTypeReducer,
  payrollTableReducer,
  payrollsActionsReducer,
} from './slices'

const rootReducer = combineReducers({
  aiButtonReducer,
  bonusPenaltyModal: bonusPenaltyModalReducer,
  announcementsReducer,
  locationsReducer,
  notificationsHandler: notificationsHandlerReducer,
  bonusPenaltyTable: bonusPenaltyTableReducer,
  showEmployeeCompensation: showEmployeeCompensationReducer,
  employeeBonusPenaltyTable: employeeBonusPenaltyTableReducer,
  employeeMgpHistoryTableFilters: employeeMgpHistoryTableFiltersReducer,
  employeeRateHistoryTableFilters: employeeRateHistoryTableFiltersReducer,
  mgpPModal: mgpModalReducer,
  rateModal: rateModalReducer,
  deleteSystemCurrencyModal: deleteSystemCurrencyModalReducer,
  payrollType: payrollTypeReducer,
  payrollTable: payrollTableReducer,
  payrollActions: payrollsActionsReducer,
  [announcementAPI.reducerPath]: announcementAPI.reducer,
  [locationsAPI.reducerPath]: locationsAPI.reducer,
  [systemCurrencyApi.reducerPath]: systemCurrencyApi.reducer,
  [bonusPenaltyApi.reducerPath]: bonusPenaltyApi.reducer,
  [timeOffLogAPI.reducerPath]: timeOffLogAPI.reducer,
  [officialInformationApi.reducerPath]: officialInformationApi.reducer,
  [militaryRegistrationApi.reducerPath]: militaryRegistrationApi.reducer,
  [militaryReservationApi.reducerPath]: militaryReservationApi.reducer,
  [employeeCompensationApi.reducerPath]: employeeCompensationApi.reducer,
  [employeeMgpHistoryApi.reducerPath]: employeeMgpHistoryApi.reducer,
  [employeeRateHistoryApi.reducerPath]: employeeRateHistoryApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [activePayrollApi.reducerPath]: activePayrollApi.reducer,
  [closedPayrollApi.reducerPath]: closedPayrollApi.reducer,
})

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        announcementAPI.middleware,
        locationsAPI.middleware,
        timeOffLogAPI.middleware,
        systemCurrencyApi.middleware,
        bonusPenaltyApi.middleware,
        officialInformationApi.middleware,
        militaryRegistrationApi.middleware,
        militaryReservationApi.middleware,
        employeeCompensationApi.middleware,
        employeeMgpHistoryApi.middleware,
        employeeRateHistoryApi.middleware,
        currenciesApi.middleware,
        activePayrollApi.middleware,
        closedPayrollApi.middleware,
      ]),
  })

export type RootStateType = ReturnType<typeof rootReducer>
export type AppStoreType = ReturnType<typeof setupStore>
export type AppDispatchType = AppStoreType['dispatch']
