import { useAppDispatch, useAppSelector } from 'hooks'
import { useCallback, useMemo } from 'react'
import { hasActiveFilter } from 'utils/helpers/has-active-filters'
import { useProfile } from '../../../../context'
import { openRateModal } from 'store'
import { TableBox } from '../table-box'
import { Table, Filters, RateFormModal } from './components'
import { useGetEmployeeRateHistoryDataQuery } from 'services/api'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'

export const RateTable = () => {
  const { idOpenProfile } = useProfile()
  const { employee } = useEmployee()

  const dispatch = useAppDispatch()

  const { mode, isOpen, payload } = useAppSelector((state) => state.rateModal)

  const { tableState, tableFilters } = useAppSelector((state) => state.employeeRateHistoryTableFilters)

  const { dates, currencies } = tableFilters

  const {
    data: employeeRateHistoryDate = { items: [], total: 0 },
    isFetching,
    isLoading,
  } = useGetEmployeeRateHistoryDataQuery({
    ...tableState,
    ...tableFilters,
    employeeId: idOpenProfile,
  })

  const handleCreateRateModal = useCallback(() => dispatch(openRateModal({ mode: 'create' })), [])

  const hasActiveFilters = useMemo(() => hasActiveFilter(tableFilters), [dates, currencies])

  return (
    <>
      <TableBox
        createRequestBtnAccess={employee?.roles.includes(RolesEnum.ROLE_FINANCIAL_MANAGER)}
        isLoadingTable={isLoading}
        tableTitle="Rate history"
        skeleton={{ filtersCount: 2, colCount: 3, rowCount: 6 }}
        onClickAddBtn={handleCreateRateModal}
        showTable={Boolean(employeeRateHistoryDate.total)}
        tableComponent={<Table isLoading={isFetching} sourceData={employeeRateHistoryDate} />}
        tableFiltersComponent={<Filters />}
        showTableFilters={hasActiveFilters}
      />
      {isOpen && <RateFormModal mode={mode} payload={payload} />}
    </>
  )
}
