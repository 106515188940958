import { ProfileTabsProps } from './types'
import { StyledTab } from './styles'

export function generateProfileTabs(
  tabs: ProfileTabsProps['tabs'],
  dropdownAction?: ({ key }: { key: string }) => void,
) {
  const filteredTabs = tabs.filter((tab) => tab.isVisible)
  const mainTabs = filteredTabs
    .slice(0, 4)
    .map((tab) => ({ ...tab, children: <StyledTab tab={tab.label} key={tab.key} /> }))
  const dropdownTabs = filteredTabs.slice(4).map((tab) => ({ ...tab, onClick: dropdownAction }))

  return { mainTabs, dropdownTabs }
}
