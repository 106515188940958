import { Tooltip } from 'antd'
import { useAppSelector } from 'hooks'
import { setClosePayrollModalOpen } from 'store'
import { ExportFileType } from 'shared'
import { useDispatch } from 'react-redux'
import { useLazyGetExportActivePayrollFileQuery } from 'services/api'
import { downloadFile } from 'utils/helpers/download-file'
import { CalendarIcon, Button, FileIcon, ExportFileBtn } from 'components'
import { TabButtons } from '../tab-buttons'
import { ActivePayrollFiltersStyles } from './styles'

export const ActivePayrollFilters = () => {
  const dispatch = useDispatch()

  const { selectedPayrollsData } = useAppSelector((state) => state.payrollActions)

  const showClosePayrollButton = Boolean(
    selectedPayrollsData.selectedPayrolls.length || selectedPayrollsData.selectedAll,
  )

  const {
    tableState: { sort, order },
  } = useAppSelector((state) => state.payrollTable)

  const [trigger, { isFetching }] = useLazyGetExportActivePayrollFileQuery()

  const handleOpenClosePayrollModal = () => {
    dispatch(setClosePayrollModalOpen(true))
  }

  const handleButtonClick = async (fileType: ExportFileType) => {
    try {
      const url = await trigger({ format: fileType, order, sort }).unwrap()
      downloadFile({ url, fileName: `active_payrolls.${fileType}` })
    } catch (err) {
      console.error('Error downloading file:', err)
    }
  }

  return (
    <ActivePayrollFiltersStyles>
      <Tooltip
        placement="bottomLeft"
        className="filters-header"
        title="You can select a date period only in the closed payroll period"
      >
        <CalendarIcon className="calendar-icon" />
        Active period
      </Tooltip>
      <div className="filters-body">
        <TabButtons />
        <ExportFileBtn loading={isFetching} onClick={handleButtonClick} />
        {showClosePayrollButton && (
          <Button type="primary" icon={<FileIcon />} onClick={handleOpenClosePayrollModal}>
            Close payroll period
          </Button>
        )}
      </div>
    </ActivePayrollFiltersStyles>
  )
}
