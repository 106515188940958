import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultPayrollTableState, defaultPayrollTableFilters } from './constants'
import { PayrollTableFilters, PayrollTableState } from './types'
import { setPayrollType } from '../payroll-type'

export const payrollTableSlice = createSlice({
  name: 'activePayrollTable',
  initialState: {
    tableState: defaultPayrollTableState,
    tableFilters: defaultPayrollTableFilters,
  },
  reducers: {
    setPayrollTableState: (state, action: PayloadAction<PayrollTableState>) => ({
      ...state,
      tableState: action.payload,
    }),
    setPayrollFilters: (state, action: PayloadAction<PayrollTableFilters>) => ({
      tableFilters: {
        ...state.tableFilters,
        ...action.payload,
      },
      tableState: defaultPayrollTableState,
    }),
    resetPayrollTableState: () => ({
      tableState: defaultPayrollTableState,
      tableFilters: defaultPayrollTableFilters,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setPayrollType, () => ({
      tableState: defaultPayrollTableState,
      tableFilters: defaultPayrollTableFilters,
    }))
  },
})

export const { setPayrollTableState, setPayrollFilters, resetPayrollTableState } = payrollTableSlice.actions
export const payrollTableReducer = payrollTableSlice.reducer
