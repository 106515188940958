// TODO: fix eslint errors
/* eslint-disable */
import { useState, useEffect } from 'react'
import CreateLink from './Modal/CreateLink'
import DeleteLink from './Modal/DeleteLink'
import EditLink from './Modal/EditLink'
import { ORG_CHART, LINKS } from '../../../../utils/constants/api'
import { LinkFromApi } from '../../../../shared/Link/data/LinkFromApi'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { RolesEnum } from 'shared'
import { OrgUnitFromApi } from '../../../../shared/OrgChart/data/OrgUnitFromApi'
import { useEmployee, assertEmployee } from '../../../../contexts/EmployeeContext'
import './Links.scss'

export const Links = () => {
  const { employee } = useEmployee()
  assertEmployee(employee)
  const [createLinkModal, setCreateLinkModal] = useState<boolean>(false)
  const [editLinkModal, setEditLinkModal] = useState<boolean>(false)
  const [deleteLinkModal, setDeleteLinkModal] = useState<boolean>(false)
  const [links, setLinks] = useState<LinkFromApi[]>([])
  const [linkToChange, setLinkToChange] = useState<LinkFromApi | null>(null)
  const [linkToDelete, deleteLink] = useState<LinkFromApi | null>(null)
  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([])

  useEffect(() => {
    getOrgUnits()
  }, [])

  useEffect(() => {
    getLinks()
  }, [])

  function getLinks() {
    fetch(LINKS, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((links: LinkFromApi[]) => setLinks(links))
  }

  function showLink({ link }: { link: any }): void {
    fetch(`${LINKS}/${link.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name: link.value.name,
        url: link.value.url,
        orgUnits: link.value.orgUnits,
        visible: !link.value.visible,
      }),
    }).then((response) => {
      if (response.ok) {
        getLinks()
      } else {
        response.json().then((jsonData) => alert(jsonData.error))
      }
    })
  }

  function editLinkModalVisibility(link: LinkFromApi): void {
    setLinkToChange(link)
    setEditLinkModal(true)
  }

  function confirmDeleteLinkModalVisibility(link: LinkFromApi): void {
    deleteLink(link)
    setDeleteLinkModal(true)
  }

  async function getOrgUnits() {
    const response = await fetch(`${ORG_CHART}?without-hierarchy=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
    const orgUnits = await response.json()
    setOrgUnits(orgUnits)
  }

  const roles = [RolesEnum.ROLE_ADMIN]
  const hasRole = roles.some((role) => employee.roles.includes(role))

  return (
    <div className="admin-panel-board">
      <div className="sub-content-block d-flex">
        <div className="sub-content-header d-flex">
          <div className="sub-title-block d-flex">
            <h3>Useful links</h3>
          </div>
          {hasRole ? (
            <div className="add-button-block">
              <Button
                text="<i className='material-icons add-item'>add</i> Add link"
                size={Size.Medium}
                type={Type.Accent}
                onClick={() => setCreateLinkModal(true)}
              />
            </div>
          ) : null}
        </div>
        {links.length > 0 && (
          <div className="sub-content-main">
            <table className="links-table">
              <thead>
                <tr>
                  <th>Link Name</th>
                  <th>Url</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {links.map((link) => (
                  <tr key={link.id}>
                    <td title={link.value.name}>{link.value.name}</td>
                    <td title={link.value.url}>{link.value.url}</td>
                    <td>
                      {hasRole && (
                        <>
                          <i className="material-icons" onClick={() => editLinkModalVisibility(link)}>
                            edit
                          </i>
                          <i className="material-icons" onClick={() => confirmDeleteLinkModalVisibility(link)}>
                            delete
                          </i>
                          <i className="material-icons" onClick={() => showLink({ link })}>
                            {link.value.visible ? 'visibility' : 'visibility_off'}
                          </i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <CreateLink
        orgUnits={orgUnits}
        opened={createLinkModal}
        onOk={() => {
          getLinks()
          setCreateLinkModal(false)
        }}
        onClose={() => setCreateLinkModal(false)}
      />
      {linkToDelete ? (
        <DeleteLink
          opened={deleteLinkModal}
          onOk={() => {
            getLinks()
            setDeleteLinkModal(false)
            deleteLink(null)
          }}
          onClose={() => {
            setDeleteLinkModal(false)
            deleteLink(null)
          }}
          link={linkToDelete}
        />
      ) : null}
      {linkToChange ? (
        <EditLink
          orgUnits={orgUnits}
          opened={editLinkModal}
          onOk={() => {
            getLinks()
            setEditLinkModal(false)
            setLinkToChange(null)
          }}
          onClose={() => {
            setEditLinkModal(false)
            setLinkToChange(null)
          }}
          link={linkToChange}
        />
      ) : null}
    </div>
  )
}
