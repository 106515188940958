import { Space } from 'antd'
import { useAppDispatch } from 'hooks'
import { openMGPModal } from 'store'
import { Button } from 'components'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { TableActionsProps } from './types'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'

export const TableAction = ({ record }: TableActionsProps) => {
  if (!record.editable) return null

  const dispatch = useAppDispatch()
  const { employee } = useEmployee()

  return (
    employee?.roles.includes(RolesEnum.ROLE_FINANCIAL_MANAGER) && (
      <Space size={8} align="center">
        <Button
          size="small"
          icon={<EditFilled />}
          onClick={() => dispatch(openMGPModal({ mode: 'edit', payload: record }))}
        />
        <Button
          size="small"
          icon={<DeleteFilled />}
          onClick={() => dispatch(openMGPModal({ mode: 'delete', payload: record }))}
        />
      </Space>
    )
  )
}
