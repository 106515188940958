import { Space, Tooltip } from 'antd'
import { ATTENDANCE_LOG } from 'navigation'
import { currencyFormatter } from 'utils/helpers/currency-formatter'
import { formattedMinutes } from 'utils/helpers/formatted-minutes'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import { EmployeeTableItem, Button, ShortcutIcon } from 'components'
import { tableColumnsMap } from './constants'
import { PayrollTableConfig } from './types'
import { Payroll } from 'models'

export const getTableColumns = (): PayrollTableConfig => [
  {
    title: <span>Employee</span>,
    width: '256px',
    dataIndex: tableColumnsMap.employeeFullName,
    render: (_, { employeeFullName, employeeAvatar, employeeId, employeeNickname }) => (
      <EmployeeTableItem
        employeeFullName={employeeFullName}
        employeeNickname={employeeNickname}
        employeeAvatar={employeeAvatar}
        employeeId={employeeId}
      />
    ),
    sorter: true,
  },
  {
    title: 'Payroll period',
    width: '216px',
    dataIndex: tableColumnsMap.startDate,
    render: (_, { startDate, endDate }) =>
      `${getFormattedDate(startDate, 'coreHRDateFormat')} - ${getFormattedDate(endDate, 'coreHRDateFormat')}`,
    sorter: true,
  },
  {
    title: 'Hours worked',
    width: '152px',
    dataIndex: tableColumnsMap.workingMinutes,
    render: (workingMinutes: Payroll['workingMinutes'], { employeeId, startDate, endDate }) => (
      <Space size={8}>
        {formattedMinutes(workingMinutes)}
        <Tooltip title="Go to attendance log" placement="bottom">
          <Button
            type="text"
            icon={<ShortcutIcon />}
            size="small"
            href={`${ATTENDANCE_LOG}?employees=${employeeId}&startDate=${startDate}&endDate=${endDate}`}
            target="_blank"
          />
        </Tooltip>
      </Space>
    ),
    align: 'right',
  },
  {
    title: 'Rate',
    width: '152px',
    dataIndex: tableColumnsMap.rate,
    render: (rate: Payroll['rate']) => currencyFormatter(rate.value, rate.symbol),
    align: 'right',
  },
  {
    title: 'Bonuses',
    align: 'right',
    width: '152px',
    dataIndex: tableColumnsMap.bonuses,
    render: (bonuses: Payroll['bonuses']) => (
      <Space direction="vertical" size={24}>
        {bonuses && bonuses.length
          ? bonuses.map((bonus, index) => <span key={index}>{currencyFormatter(bonus.value, bonus.symbol)}</span>)
          : '-'}
      </Space>
    ),
  },
  {
    title: 'Penalties',
    align: 'right',
    width: '152px',
    dataIndex: tableColumnsMap.penalties,
    render: (penalties: Payroll['penalties']) => (
      <Space direction="vertical" size={24}>
        {penalties && penalties.length
          ? penalties.map((penalty, index) => (
              <span key={index}>{currencyFormatter(penalty.value, penalty.symbol)}</span>
            ))
          : '-'}
      </Space>
    ),
  },
]
