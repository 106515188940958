import { TabContainer } from '../TabContainer'
import { BlockInfo, BlockInfoSkeletonItem } from '../../BlockInfo'

import { FirstNameEN } from '../../Fields/generalInfo/FirstNameEN'
import { OtherNameEN } from '../../Fields/generalInfo/OtherNameEN'
import { LastNameEN } from '../../Fields/generalInfo/LastNameEN'
import { FirstName } from '../../Fields/generalInfo/FirstName'
import { OtherName } from '../../Fields/generalInfo/OtherName'
import { LastName } from '../../Fields/generalInfo/LastName'
import { Nickname } from '../../Fields/generalInfo/Nickname'
import { GenderPronounce } from '../../Fields/generalInfo/GenderPronounce'
import { DateOfBirth } from '../../Fields/generalInfo/DateOfBirth'

import { Country } from '../../Fields/generalInfo/Country'
import { City } from '../../Fields/generalInfo/City'
import { PhoneNumber } from '../../Fields/generalInfo/PhoneNumber'
import { Email } from '../../Fields/generalInfo/Email'
import { SocialNetwork } from '../../Fields/generalInfo/SocialNetwork'

import { AccessLevel } from '../../Fields/generalInfo/AccessLevel'
import { useProfile } from '../../context'
import { useEmployee } from 'contexts/EmployeeContext'
import { RolesEnum } from 'shared'

export const GeneralInfoTab = () => {
  const { contactInfo, isCurrentUserProfile, isManagerOpenProfile } = useProfile()
  const { employee } = useEmployee()

  return (
    <TabContainer>
      <BlockInfo title="Personal Info">
        <FirstNameEN />
        <OtherNameEN />
        <LastNameEN />
        <FirstName />
        <OtherName />
        <LastName />
        <Nickname />
        <GenderPronounce />
        <DateOfBirth />
      </BlockInfo>

      <BlockInfo title="Contact Info">
        <Country />
        <City />
        {contactInfo ? <PhoneNumber /> : <BlockInfoSkeletonItem />}
        {contactInfo ? <Email /> : <BlockInfoSkeletonItem />}
        {contactInfo ? <SocialNetwork /> : <BlockInfoSkeletonItem />}
      </BlockInfo>

      {(isCurrentUserProfile || isManagerOpenProfile || employee?.roles.includes(RolesEnum.ROLE_HR)) && (
        <BlockInfo title="Other Info">
          <AccessLevel />
        </BlockInfo>
      )}
    </TabContainer>
  )
}
