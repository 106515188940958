import { useForm } from 'react-hook-form'
import { MilitaryRegistrationFormField, MilitaryRegistrationFormValues } from './types'
import { defaultValues } from './constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './schema'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useCopyToClipboard } from 'hooks/copy-to-clipboard/use-copy-to-clipboard'
import { useAppDispatch } from 'hooks'
import { useGetMilitaryRegistrationQuery, useUpdateMilitaryRegistrationMutation } from 'services/api'
import { triggerNotification } from 'store'
import { serializeMilitaryRegistration } from './utils'

export const useMilitaryRegistrationForm = (employeeId: string) => {
  const dispatch = useAppDispatch()
  const { data: militaryRegistrationResponse, isLoading } = useGetMilitaryRegistrationQuery({ employeeId })
  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({})
  const [updateMilitaryRegistration] = useUpdateMilitaryRegistrationMutation()
  const methods = useForm<MilitaryRegistrationFormValues>({
    defaultValues,
    values: militaryRegistrationResponse,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const { copyToClipboard, isCopied } = useCopyToClipboard()
  const watchDocumentsLink = methods.watch('documentLink')

  const handleCopyToClipboard = () => {
    if (!watchDocumentsLink) return
    copyToClipboard(watchDocumentsLink)
  }

  const isValidLink = militaryRegistrationResponse?.documentLink

  const stopEditing = async (field: MilitaryRegistrationFormField, forceExit: boolean = false) => {
    if (forceExit) {
      methods.reset({ ...(militaryRegistrationResponse ?? defaultValues) })
      setEditingFields((prev) => ({ ...prev, [field]: false }))
      return
    }

    const isValid = await methods.trigger(field)

    if (!isValid) {
      setEditingFields((prev) => ({ ...prev, [field]: true }))
      return
    }

    setEditingFields((prev) => ({ ...prev, [field]: !!methods.formState.errors[field]?.message || false }))
  }

  const startEditing = (field: MilitaryRegistrationFormField) => {
    const activeFieldWithError = (Object.keys(editingFields) as MilitaryRegistrationFormField[]).find(
      (key) => editingFields[key] && methods.formState.errors[key],
    )

    if (activeFieldWithError) {
      setEditingFields((prev) => ({ ...prev, [activeFieldWithError]: true }))
      return
    }
    if (field) methods.clearErrors(field)
    setEditingFields({ [field]: true })
  }

  const onSubmit = async (data: MilitaryRegistrationFormValues) => {
    try {
      if (isEqual(data, militaryRegistrationResponse || defaultValues)) {
        return
      }
      const seraialized = serializeMilitaryRegistration(data)
      await updateMilitaryRegistration({ employeeId, ...seraialized }).unwrap()
    } catch (error) {
      dispatch(triggerNotification({ type: 'error', message: 'Error occurred while editing military registration' }))
      methods.reset(militaryRegistrationResponse || defaultValues)
      console.error(error)
    } finally {
      setEditingFields({})
    }
  }

  function handleBlur() {
    methods.handleSubmit(onSubmit)()
  }

  return {
    isLoading,
    methods,
    editingFields,
    isValidLink,
    isLinkCopied: isCopied,
    watchDocumentsLink,
    onSubmit,
    handleBlur,
    startEditing,
    stopEditing,
    handleCopyToClipboard,
  }
}
