import { memo } from 'react'
import { AddIcon, Button } from 'components'
import { NoData, NoResults } from 'components/UI/table'
import { SkeletonTable } from 'components/UI/skeleton-table'
import { TableBoxStyles } from './styles'
import { TableBoxProps } from './types'

export const TableBox = memo((props: TableBoxProps) => {
  const {
    isLoadingTable,
    tableTitle,
    skeleton,
    onClickAddBtn,
    showTable,
    tableComponent = null,
    tableFiltersComponent = null,
    showTableFilters,
    createRequestBtnAccess,
  } = props

  return (
    <TableBoxStyles>
      {isLoadingTable && <SkeletonTable {...skeleton} />}
      <h4 className="table-title">{tableTitle}</h4>
      <div className="table-filters">
        {(showTable || showTableFilters) && tableFiltersComponent}
        {createRequestBtnAccess && (
          <Button customType="core-hr-secondary" icon={<AddIcon />} onClick={onClickAddBtn}>
            Add
          </Button>
        )}
      </div>
      {showTable ? tableComponent : <>{showTableFilters ? <NoResults size="small" /> : <NoData size="small" />}</>}
    </TableBoxStyles>
  )
})
