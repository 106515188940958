import { type FC, type SVGProps, type ReactNode } from 'react'
import style from './BlockInfo.module.scss'
import { LineSkeleton } from '../../UI/LineSkeleton'
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'
import { Tooltip } from 'antd'
import { StyledIconButton } from './styles'

interface BlockInfoProps {
  title: string
  Icon?: FC<SVGProps<SVGSVGElement>>
  children: ReactNode
  tooltip?: ReactNode
}

export const BlockInfo: FC<BlockInfoProps> = ({ title, Icon, children, tooltip }) => (
  <div className={style.blockInfo}>
    {title ? (
      <div className={style.titleBlock}>
        {Icon && (
          <div className={style.iconWrapper}>
            <Icon className={style.icon} />
          </div>
        )}
        <h4 className={style.title}>{title}</h4>
        {tooltip && (
          <>
            <Tooltip
              title={tooltip}
              color="white"
              trigger={['click']}
              placement="rightBottom"
              overlayStyle={{ maxWidth: '400px' }}
            >
              <div className={style.iconInfo}>
                <StyledIconButton>
                  <EditorPanelIcon label="Info" />
                </StyledIconButton>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    ) : (
      <LineSkeleton height="28px" width="250px" />
    )}
    <hr className={style.hr} />
    <table className={style.table}>
      <tbody>{children}</tbody>
    </table>
  </div>
)
