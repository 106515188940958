import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isPlainObject from 'lodash/isPlainObject'

type Filters = undefined | Record<string, any>

export const hasActiveFilter = (filters: Filters, defaultFilters: Filters = undefined): boolean => {
  if (isEmpty(filters)) {
    return false
  }

  if (isEqual(filters, defaultFilters)) {
    return false
  }

  return Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return Boolean(value.length)
    }
    if (isPlainObject(value)) {
      return !isEmpty(value)
    }
    return Boolean(value)
  })
}
