import * as yup from 'yup'
import { MilitaryRegistrationFormValues } from './types'

export const validationSchema: yup.ObjectSchema<MilitaryRegistrationFormValues> = yup.object({
  status: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  attachedTcrss: yup.boolean().nullable(),
  tcrssByRegistration: yup
    .string()
    .nullable()
    .max(255, 'TCRSS must be at most 255 characters')
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  basisDeferment: yup.boolean().nullable(),
  dataClarified: yup.boolean().nullable(),
  documentLink: yup
    .string()
    .nullable()
    .max(2048, 'Documents link must be at most 2048 characters')
    .test('is-valid-url', 'Documents link format is not valid', (value) => {
      if (!value) return true
      return /^(http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/.*)?$/.test(value)
    })
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
})
