import * as yup from 'yup'
import { MilitaryReservationFormValues } from './types'

export const validationSchema: yup.ObjectSchema<MilitaryReservationFormValues> = yup.object({
  employmentForm: yup
    .string()
    .nullable()
    .max(50, 'Employment form must be at most 50 characters')
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  reservation: yup.boolean().nullable(),
  dateEnd: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  status: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  criticality: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
  notes: yup
    .string()
    .nullable()
    .max(255, 'Notes must be at most 255 characters')
    .transform((value) => (value === '' ? null : value))
    .notRequired(),
})
