import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultPayrollType } from './constants'
import { PayrollTypeEnum } from 'shared'

export const payrollTypeSlice = createSlice({
  name: 'payrollType',
  initialState: defaultPayrollType,
  reducers: {
    setPayrollType: (_state, action: PayloadAction<PayrollTypeEnum>) => action.payload,
  },
})

export const { setPayrollType } = payrollTypeSlice.actions
export const payrollTypeReducer = payrollTypeSlice.reducer
